.admin-tasks {
  display: block;
}

.admin-tasks a:link,
.admin-tasks a:visited {
  color: steelblue !important;
}

.admin-tasks a:hover {
  color: orangered !important;
}

.admin-tasks table.case-list {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.admin-tasks table.case-list thead tr th {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  text-align: left;
}
.admin-tasks table.case-list tbody tr td {
  padding: 5px;
  border: 1px solid #ccc;
}

.admin-tasks label {
  position: relative;
  top: -1px;
}

.admin-tasks .avatar {
  width: 48px;
  height: 48px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.admin-tasks button.refresh {
    padding: 4px 15px;
    margin-left: 15px;
}