.about-me {
  display: block;
}

.about-me ul {
  list-style: none;
}

.about-me ul li:empty {
  margin-bottom: 40px;
}

.about-me select.month {
  border-radius: 5px 0px 0 5px;
  border-right: 0;
}

.about-me select.year {
  border-radius: 0 5px 5px 0;
}

.about-me div.ib {
  display: inline-block;
}

.about-me .remove-btn {
  transition: 0.3s ease-in;
}

.about-me .remove-btn:hover {
  color: red;
  transition: 0.3s ease-in-out;
}

.about-me .edit-btn {
  transition: 0.3s ease-in;
}

.about-me .edit-btn:hover {
  color: steelblue;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .about-me div.ib {
    display: block;
  }
}
