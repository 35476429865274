.my-body-container {
  width: 99%;
  max-width: 99%;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.my-account .dn {
  display: none;
}

.my-account {
  display: block;
  margin-top: 70px;
}

.my-account hr {
  margin: 0;
  padding: 0;
}

.my-account .menu {
  /* border-right: 1px solid #eee; */
  flex-shrink: 0;
  width: 320px;
  max-width: 100%;
  transition: 0.3s ease-in-out;
}

.my-account .menu.hide {
  width: 120px !important;
  max-width: 120px !important;
  transition: 0.3s ease-in-out;
}

.my-account .menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.my-account .menu ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  /* width: 50px; */
  overflow-x: hidden;
}

.my-account .menu ul li a {
  /* line-height: 3em;   */
  transition: 0.3s ease-out;
}

.my-account .menu ul li.active a {
  /* background-color: maroon !important; */
  color: orangered !important;
  transition: 0.3s ease-in;
  /* border-left: 5px solid maroon; */
  /* padding-left: 1px; */
}

.my-account input[type="checkbox"] {
  width: 20px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.my-account label {
  position: relative;
  top: 2px;
  font-weight: 400;
  /* margin-bottom: 10px; */
}

.my-account ul.inline {
  list-style: none;
  margin: 0;
  padding: 0;
}

.my-account ul.inline li {
  display: inline-block;
}

.my-account table.aca-list {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.my-account table.aca-list thead {
  font-weight: bold;
}

.my-account table.aca-list thead tr td {
  padding: 20px !important;
  background-color: #f1f1f1;
}

.my-account table.aca-list thead tr td:last-child {
  width: 60px;
}

.my-account table.aca-list tr.active {
  background-color: #333;
  color: white;
}

.my-account table.aca-list tr td {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.my-account table.aca-list tbody tr:not(:last-child):hover {
  cursor: pointer;
  background-color: #ddd;
  transition: 0.3s ease-in-out;
  color: black;
}

.my-account table.course-list {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.my-account table.course-list thead tr td {
  background-color: #f1f1f1;
  padding: 20px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.my-account table.course-list thead tr td:first-child {
  text-align: center;
  width: 40px;
}

.my-account table.course-list thead tr td:nth-child(2) {
  width: 120px;
}

.my-account table.course-list thead tr td:last-child {
  width: 60px;
}

.my-account table.course-list tbody tr td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.my-account table.course-list tbody tr td:first-child {
  text-align: center;
}

/* ========================== */
.my-account .tool-icon {
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 10px;
  transition: 0.3s ease-in-out;
}

.my-account .tool-icon:hover {
  background-color: maroon;
  color: white;
}

/* .my-account table.course-list tr td:first-child {  
  width: 50px;
} */

.my-account input[type="password"] {
  border-radius: 15px;
  width: 280px !important;
  max-width: 100% !important;
}

.my-account input[type="text"].short,
.my-account input[type="password"].short {
  border-radius: 15px;
  width: 130px;
  max-width: 100%;
}

.my-account .fool-chrome {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
}

.my-account .fool-chrome input[type="password"],
.my-account .fool-chrome input[type="text"] {
  height: 0 !important;
  width: 0 !important;
  height: 0px;
  padding: 0;
  margin: 0;
  border: 0;
}

.my-account select {
  padding: 7px;
  width: 340px;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  font-family: "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1em;
}

/* */

.security-settings {
  position: relative;
  width: auto;
}

.security-settings ul.bullet {
  margin: auto;
  list-style: disc !important;
  list-style-type: disc !important;
}

/* .security-settings ul.info-bar li {
  line-height: 2em;
} */

.security-settings ul.qr-steps {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none !important;
}

.security-settings ul.qr-steps li:empty {
  display: block;
}

.security-settings ul.qr-steps li:empty {
  margin: 40px 0;
}

.security-settings .qr-code {
  font-family: "Roboto Mono", monospace;
}

.security-settings .qr-text {
  display: inline-block;
  background-color: #f5f5f5;
  width: auto;
  border-radius: 4px;
  margin: 10px 0;
}
.security-settings input[type="text"],
.security-settings input[type="password"] {
  font-family: "Roboto Mono", monospace;
  width: 250px !important;
  max-width: 100% !important;
}

.security-settings .qr-text input[type="text"] {
  font-family: "Roboto Mono", monospace;
  text-align: center;
  border: 0;
  border-bottom: 1px solid #333;
  border-radius: 0;
  min-width: 15px;
  width: 15px;
  max-width: 15px;
  background-color: transparent;
  margin: 0 7px 0 7px;
  font-size: x-large;
  font-weight: bold;
}

.security-settings .break {
  word-break: break-all;
}

.address-location ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.address-location ul li {
  margin: 20px 0 20px 0;
}

.users-migration ul.bullet {
  list-style: square !important;
  list-style-type: square !important;
  margin-left: 20px;
}

.users-migration ul.bullet li {
  display: list-item !important;
  list-style-type: square !important;
}

@media screen and (max-width: 740px) {
  .my-account .myl {
    padding: 0 !important;
    padding-left: 18px !important;
    margin: 0;
  }
  .my-body-container {
    width: 98%;
  }

  .my-account .menu {
    display: inline-block;
    width: 100%;
  }

  .my-account .section-expander {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }

  .my-account .menu .section-expander .header {
    display: none;
  }

  .my-account .menu ul {
    display: inline-block;
  }

  .my-account .menu .h {
    display: none;
  }

  .my-account .menu ul li {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 15px;
    display: inline-block;
    overflow-x: hidden;
  }

  .my-account table tr th,
  .my-account table tr td {
    width: auto !important;
  }
}
