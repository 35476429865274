.date-picker {
  display: block;
}

.date-picker select {
  width: auto !important;
  padding: 10px !important;
  font-family: "Roboto Mono", "Tahoma", "Verdana";
  font-size: 1em !important;
  color: #666 !important;
}

.date-picker select:first-child {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid inherit !important;
}

.date-picker select:nth-child(2) {
  border-radius: 0;
  border-left: 0 !important;
  border-right: 0 !important;
}

.date-picker select:last-child {
  border-radius: 0px 5px 5px 0;
  border-left: 1px solid inherit !important;
}
