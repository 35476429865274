.noti-panel {
  color: white !important;
  z-index: 9999;
  display: block;
  position: fixed;
  transition: 0.2s ease-in-out;
  top: 60px;
  right: 5px;
  width: 380px;
  max-width: 100%;
  height: 420px;
  background-color: #333;
  background-color: rgba(3, 3, 3, 0.85);
  backdrop-filter: blur(5);
  /* overflow-y: scroll; */
  border: 1px solid #666;
  box-shadow: 2px -2px 20px #000;
  opacity: 1;
  border-radius: 20px 0 0 20px;
}

.noti-panel .header {
  border-bottom: 1px solid #666;
}
.noti-panel .body {
  height: 350px;
  overflow-y: scroll;
}

.noti-panel.hidden {
  height: 0;
  /* overflow: hidden; */
  opacity: 0;
  transition: 0.2s ease-in-out;
  z-index: -1000;
}

.noti-panel ul li {
  border-bottom: 1px solid #666;
}

/* track */
.noti-panel .body::-webkit-scrollbar {
  background-color: #666;
  border-radius: 2px;
}

/* Handle */
.noti-panel .body::-webkit-scrollbar-thumb {
  background: seagreen;
  border-radius: 5px;
  transition: 0.3s ease-out;
}

.noti-panel .body::-webkit-scrollbar-thumb:hover {
  background: maroon;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .noti-panel {
    width: 100%;
    height: 100%;
    right: 0;
    border-radius: 0;
  }
}
