.wall-photo {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 85%;
  max-width: 1366px;
  min-height: 75vh;
  left: 50%;
  top: 60px;
  transform: translateY(-20%);
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;

  background-color: #333;
  background-color: rgb(30, 30, 30, 0.85);
  backdrop-filter: blur(5px);

  box-shadow: 15px 15px 30px #666;
}

.wall-photo.hidden {
  /* height: 0;
  overflow: hidden; */
  z-index: -9999;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.wall-photo .thumbs {
  height: 80px;
  width: 100%;
}

.wall-photo .thumbs .pics {
  cursor: pointer;
  height: 60px;
  width: 60px;
  display: inline-block;
  border: 1px solid #555;
  margin-right: 5px;
  transition: 0.3s ease-in-out;

  background-position: center center;
  background-size: cover;
  /* background-image: url("https://localhost:44372/cdn/1903822/albums/wall/hero-1903822.jpg?v=Hi5SX09Jw583"); */
}

.wall-photo .thumbs .pics:first-child {
  margin-left: 15px;
}

.wall-photo .thumbs .pics:hover {
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
}

.wall-photo .thumbs .pics.active {
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 0px 10px 1px #999;
}

.wall-photo .close {
  cursor: pointer;
  width: 48px;
  position: relative;
  top: -2px;
  text-align: center;
  /* border: 1px solid #ccc; */
  font-size: 2.25em;
  color: #999 !important;
  margin-right: 10px;
  transition: 0.3s ease-in-out;
}

.wall-photo .close:hover {
  color: white !important;
  transition: 0.3s ease-in-out;
}
.wall-photo .blow-up {
  background-size: cover;
  background-position: 75% center;
  height: 70vh;
  width: 100%;
  display: block;
  /* position: relative; */
}

@media screen and (max-width: 740px) {
  .wall-photo {
    width: 95%;
    /* height: 95%; */
    top: 25%;
    left: 5px;
    transform: translateY(-25%);
    transform: translateX(5px);
  }

  .wall-photo .thumbs .pics {
    height: 50px;
    width: 50px;
  }
}

@media screen and (max-height: 740px) {
  .wall-photo {
    width: 98%;
    height: 100%;
    top: 5px;
    /* left: 0; */
    /* margin-left: auto;
    margin-right: auto; */
    /* transform: translateY(5%);
    transform: translateX(50%); */
  }

  .wall-photo .blow-up {
    height: 480px;
  }
}

@media screen and (max-height: 640px) {
  .wall-photo .blow-up {
    height: 320px;
  }
}
