.my-wall {
  display: block;
  width: 100%;
}

.my-wall ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.divider {
  border-bottom: 1px solid #f1f1f1;
}

.my-wall input[type="text"] {
  background-color: #fafafa;
  border-left: 5px solid #f1f1f1;
  border-radius: 5px;
  box-sizing: border-box;
  border: 0;
  color: #333;
  font-size: medium;
  padding: 10px 0 10px 5px;
  width: 98%;
  /* border-bottom: 1px solid #ccc; */
}

.my-wall .post-thumbs {
  margin-bottom: 10px;
}
.my-wall .post-thumbs img {
  width: auto;
  height: 60px;
  margin-right: 1px;
  cursor: pointer;
}

.my-wall .post-thumbs video {
  width: 100%;
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.my-wall video {
  outline: none;
  border: 1px solid #ccc;
}

.my-wall textarea {
  border-radius: 5px;
  padding: 10px 0 10px 5px;
  background-color: #fafafa;
  border: 0;
  box-sizing: border-box;
  border-left: 5px solid #f1f1f1;
  font-size: medium;
  font-family: "system-ui", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  width: 98%;
  /* border-bottom: 1px solid #ccc; */
}

.my-wall .post-body img {
  width: auto;
  max-width: 100%;
}

.my-wall .post-body a:link,
.my-wall .post-body a:visited {
  color: #2b579a;
  border-bottom: 1px solid steelblue;
}

.my-wall .post-icons .ico {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.my-wall .icons .ico:hover {
  color: steelblue;
  transition: 0.3s ease-in-out;
}
.my-wall .button-area input[type="button"] {
  border: 0;
}

.my-wall .button-area input[type="button"]:first-child {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid #ccc;
}

.my-wall .button-area input[type="button"]:last-child {
  border-radius: 0 5px 5px 0px;
  border-right: 0;
}

.my-wall .col-expand-btn {
  color: #666;
  transition: 0.3s ease;
  margin-top: 10px;
  /* float: right; */
}
.my-wall .col-expand-btn:hover {
  color: maroon;
  transition: 0.3s ease;
}

.my-wall .edit-post {
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#postEntry {
  height: 0;
  display: block;
  width: 100%;
  overflow: hidden;
  transition: 0.3s ease;
}

#postEntry.show {
  height: auto;
  transition: 0.3s ease;
  margin-bottom: 20px;
}


