.inline-messagebar {
  display: inline-block;
  /* border-radius: 20px;   */
  line-height: 1.25em;
  font-size: 0.85em;
  opacity: 1;
  transition: 0.3s;
  font-family: Montserrat, Verdana, Geneva, Tahoma, sans-serif;
  flex-grow: 1;
}

.inline-messagebar.hide {
  transition: 0.3s ease-in-out;
  /* border-radius: 0; */
  opacity: 0;
  /* font-size: 0em;   */
  overflow: hidden;
  display: none;
  /* height: 20px; */
  /* margin-left: -10px; */
}

.inline-messagebar input[type="button"] {
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 2px;
  padding: 3px 7px;
  letter-spacing: normal;
  background-color: #72a159;
  font-size: small;
  transition: 0.3s ease-in-out;
}

.inline-messagebar input[type="button"]:hover {
  background-color: green;
  transition: 0.3s ease-in-out;
}

.inline-messagebar .padflex {
  display: flex;
  position: relative;
  align-items: center;
  /* margin-top: -5px; */
  padding: 2px;
  padding-left: 5px;
}

.inline-messagebar .color-blue {
  color: steelblue;
}

.inline-messagebar .color-green {
  color: green;
}

.inline-messagebar .color-purple {
  color: purple;
}

.inline-messagebar .color-red {
  color: red;
}

.inline-messagebar .color-orange {
  color: #ff6600;
}
