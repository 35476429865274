.info-bar {
  color: #333;
  font-size: medium;
  margin-bottom: 20px;
  transition: 0.3s ease-in-out;
}

.info-bar hr {
  border: 0;
  border-top: 1px dashed #333;
  margin-top: 10px !important;
}

.info-bar .info-panel {
  height: 0;
  border-radius: 20px 0 15px 5px;
  background-color: lemonchiffon;
  font-family: Montserrat, Verdana, Geneva, Tahoma, sans-serif;
  /* transition: 0.5s ease; */
  overflow: hidden;
}

.info-bar .title {
  font-size: small;
  padding: 5px;
}

.info-bar .info-panel.show {
  height: auto;
  border-radius: 20px 0 15px 5px;
  /* transition: 0.5s ease; */
  /* border: 1px solid #ccc; */
  /* overflow-y: scroll;   */
}

.info-bar .info-body p {
  font-size: small;
}

.info-bar .icon-bar {
  background-color: lemonchiffon;
  border-radius: 4px 4px 0 0;
  bottom: -3px;
  color: #666;
  height: 28px;
  position: relative;
  text-align: center;
  transition: 0.5s ease-out;
  min-width: 32px;
  cursor: pointer;
}

.info-bar .icon-bar:hover {
  color: #ff6600;
  transition: 0.3s ease-in;
}

.info-bar .icon-bar.on {
  color: #ff6600;
  transition: 0.3s ease-in;
  /* border: 1px solid #ccc; */
  border-bottom: 0;
}

.info-bar .title-bar {
  display: flex;
  align-items: center;
}

.info-bar .title-bar :last-child {
  margin-left: auto;
  margin-bottom: 0px;
}

.info-bar .bulb {
  cursor: pointer;
  font-size: 1.5em;
  position: relative;
  top: -5px;
  padding-right: 5px;
}

.info-bar ul {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  list-style: square !important;
  list-style-type: square !important;
  /* font-size: small; */
  line-height: 2em;
}
