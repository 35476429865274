.hash-tags {
  display: block;
  min-height: 100vh;
  margin-top: 80px;
  border-radius: 5px;
  width: 640px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.hash-tags ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.hash-tags a:link,
.hash-tags a:visited {
  color: steelblue !important;
}

.hash-tags a:hover {
  color: maroon !important;
}

.hash-tags .avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-bottom: -15px;
  margin-right: 10px;
  max-width: 100%;
  position: relative;
  width: 40px;
  /* border: 1px solid #ccc; */
}

.hash-tags .post-thumbs {
  margin-bottom: 10px;
}
.hash-tags .post-thumbs img {
  width: auto;
  height: 75px;
  margin-right: 1px;
  cursor: pointer;
}

.hash-tags .post-thumbs video {
  width: 100%;
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.hash-tags button.go {
  padding: 6px 10px !important;
  border-radius: 0 5px 5px 0;
}

.hash-tags input[type="text"].search {
  padding: 5px 5px !important;
  background-color: #f5f5f5;
  border: 1px solid #999;  
  font-size: small;
  min-width: 225px;
  max-width: 100%;
  border-radius: 5px 0 0 5px;
}
