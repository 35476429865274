/* .photo-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1px;
}

.photo-column {
  flex: 24%;
  max-width: 24%;
  padding: 0 3px;
} */

.photo-album {
  min-height: 180px;
  margin-right: 1%;
  flex-basis: 150px;
  margin-bottom: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  overflow: hidden;
  /* border: 1px solid #ccc; */

  object-fit: cover;
}

/* .photo-album img {
  object-fit: contain;
  cursor: pointer;
  height: 100px;
  width: auto;
  display: inline-block;
  background-color: white;
} */

.photo-album .thumb {
  height: 150px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  background-size: cover;
}
/* .flex .photo-album:last-child {
  margin-right: auto;
} */

.photo-album .crud .icon {
  color: #666 !important;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.photo-album .crud .icon:hover {
  color: steelblue !important;
  transition: 0.3s ease-in-out;
}

.photo-album .crud .icon:not(:last-child) {
  margin-right: 15px;
}

.photo-album button.pa {
  padding: 0px 10px !important;
  border-radius: 2px !important;
}

.photo-album button.pa span.cap {  
  position: relative;
  top: -4px;
}

.photo-album button.pa span.ix {  
  position: relative;
  top: -2px;
}

@media screen and (max-width: 640px) {
  .photo-album {
    flex: 100%;
    width: 100%;
    max-width: 100%;
    height: 320px;
    /* padding: 0 3px; */
  }
}
