.my-case-reports {
  display: block;
}

.my-case-reports table.case {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.my-case-reports table.case thead tr th {
  padding: 20px !important;
  border: 1px solid #999;
  text-transform: uppercase;
}

.my-case-reports table.case tbody tr td {
  padding: 15px !important;
  border: 1px solid #999;
}

.my-case-reports table.case tbody tr.user {
  background-color: #f6dbdb;
}

.my-case-reports table.case tbody tr.system {
  background-color: #e9e5d5;
}

.my-case-reports .avatar {
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
