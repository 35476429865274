body {
  background-color: white;
}

.login-page {
  background-color: #f5f5f5;
}

input[type="checkbox"].agree {
  height: 24px;
  width: 24px;
  position: relative;
  vertical-align: middle;
  bottom: 1px;
  margin-right: 10px;
}

.login-page .container {
  width: 940px;
  max-width: 100%;
  min-height: calc(100vh - 280px);
  margin-left: auto;
  margin-right: auto;
}

/* .container {
  width: 940px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
} */

.login-page .login-banner {
  min-height: 65vh;
  /* border-bottom: 1px solid #ccc; */
}

.login-page .login-box {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 5px 5px 10px #ccc;
  margin-bottom: 20px;
  margin-top: 20px;
}

.login-page .login-area {
  min-height: 25vh;
}

.login-page ul.login {
  list-style: none;
  margin: 0;
  padding: 0;
}

.login-page .fool-chrome {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
  display: inline-block;
}

.login-page .rcodes ul {
  margin-left: 5px;
  list-style: none;
  color: #999 !important;
}

/* .login-page ul li {
  line-height: 3em;
} */

.login-page ul.login a:link,
.login-page ul.login a:visited {
  color: steelblue;
}

.login-page ul.login a:hover {
  border-bottom: 1px solid steelblue;
}

.login-page ul.login li input[type="text"],
.login-page ul.login li input[type="password"] {
  font-size: large;
  line-height: large;
  padding: 10px;
  width: 99%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  font-family: "Montserrat", "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.login-page ul.login li input[type="text"].short,
.login-page ul.login li input[type="password"].short {
  min-width: 180px !important;
  width: 180px;
  max-width: 100%;
  /* font-weight: bold !important; */
}

.login-page ul.login li input[type="text"].user {
  width: 175px;
  border: 0;
  background-color: white;
  border-bottom: 2px solid #ccc;
  /* font-weight: bold !important; */
}

.login-page ul.login li input[type="text"].auth-code {
  font-family: "Roboto Mono", monospace;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  border-radius: 20px;
  font-size: large;
  text-align: center;
}

.login-page ul.login li input[type="submit"],
.login-page ul.login li input[type="button"] {
  transition: 0.5s;
  /* line-height: 3em; */
  font-size: large;
  padding: 15px 10px;
  width: 100%;
  background-color: tomato;
  color: white;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
}

.login-page ul.login li input[type="submit"]:hover {
  background-color: maroon;
}

.login-page ul.login li input[type="button"] {
  background-color: #66cc00;
  letter-spacing: 0px;
}

.login-page ul.login li input[type="button"]:hover {
  background-color: #119900;
}

.login-page ul.login select {
  width: 99%;
  box-sizing: border-box;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
}

.login-page button.google {
  border: 1px solid #ccc;
  outline: 0;
  padding: 5px 10px 10px 5px;
  width: auto;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-out;
  letter-spacing: normal;
}

.login-page button.google:hover {
  background-color: #e1e1e1;
}
