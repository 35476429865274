/* containers */
/* below images are dummy or their locations will be coming from the database records. */

.body-container {
  /* color:white; */
  z-index: 10;
  width: 920px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  min-height: 100vh;
  margin-bottom: 100px;

  /* border: 1px solid #f1f1f1; */
  border-top: 0;
  border-radius: 0 0 15px 15px;
}

.body-container .page {
  z-index: 11;
  min-height: 1024px;
}

.post-box {
  min-height: 220px;
  /* border: 1px solid #ccc; */
  background-color: #fff;
  /* border-radius: 2px; */
}

.example-enter {
  opacity: 0;
}

.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-exit {
  opacity: 1;
}

.example-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.avatar2 {
  width: 40px;
  height: 40px;
  border: 1px solid #eee;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid saddlebrown;
  border-radius: 50%;
}


.post-block {
  line-height: 1.25em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 30px;
  /* box-shadow: 5px 5px 10px #f1f1f1;  */
}
