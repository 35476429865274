.feeds {
  display: block;
}

.feeds ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.feeds a:link,
.feeds a:visited {
  color: steelblue !important;
}

.feeds a:hover {
  color: maroon !important;
}

.feeds .avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-bottom: -15px;
  margin-right: 10px;
  max-width: 100%;
  position: relative;
  width: 40px;
  /* border: 1px solid #ccc; */
}

.feeds .post-thumbs {
  margin-bottom: 10px;
}
.feeds .post-thumbs img {
  width: auto;
  height: 75px;
  margin-right: 1px;
  cursor: pointer;
}

.feeds .post-thumbs video {
  width: 100%;
  max-width: 100%;
  height: auto;  
  cursor: pointer;
}