.reaction-panel {
  display: inline-block;
  position: relative;
}

.reaction-panel .close {
  font-size: 2em;
}

.reaction-panel .close:hover {
  cursor: pointer;
  color: red;
}
.reaction-panel button {
  background-color: white;
  color: black;
  font-size: 1em;
  border: 1px solid #ccc;
  padding: 0px 5px;
  letter-spacing: normal;
  border-radius: 10px;
}

.reaction-panel button span {
  color: steelblue;
  position: relative;
  /* font-size: 1.5em; */
  top: -3px;
}

.reaction-panel button:hover {
  background-color: #333;
  color: white !important;
}

.reaction-panel button:hover span {
  color: white !important;
}

.reaction-panel button.active {
  background-color: #333;
  color: white;
}

.reaction-panel .more {
  z-index: 9999;
  opacity: 1;
  position: absolute;
  text-align: left;
  height: auto;
  width: 390px;
  right: 0;
  top: 0px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  line-height: 2em;
  box-shadow: 2px 2px 20px #999;
  transition: 0.3s ease-in-out;
}

.reaction-panel .more.hidden {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.reaction-panel .more ul li {
  padding: 0 5px;
  transition: 0.3s ease-in-out;
}
.reaction-panel .more ul li .menu:hover {
  display: block;
  background-color: #ddd;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 5px;
}

.reaction-panel .emote {
  transition: 0.3s ease-in-out;
}

.reaction-panel .emote:hover {
  color: maroon;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
