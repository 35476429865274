.content-block {
  min-height: 420px;
  width: 100%;
  display: block;
}

.content-block .menu-bar {
  display: block;
}

.content-block .menu-bar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content-block .menu-bar ul li {
  display: inline-block;
  margin-right: 20px;
}

.content-block .menu-bar ul li:last-child {  
  margin-right: 0;
}



.content-block .menu-bar ul li a {
  transition: 0.3s ease-out;
  display: inline-block;
  /* margin-right: 10px; */
}

.content-block .menu-bar ul li.active a {
  transition: 0.3s ease-in;
  background-color: maroon;
  color: white !important;
  padding: 5px;
  border-radius: 4px;
}
