.show-moretext {
  display: block;
}

.show-moretext .more {
  color: steelblue;
  cursor: pointer;
}

.show-moretext .more:hover {
  background-color: #e8ebff;
}

.show-moretext a:link,
.show-moretext a:visited {
  border-bottom: 0 !important;
}

.show-moretext a:hover {
  border-bottom: 0 !important;
  background-color: #e8ebff;
}

.show-moretext .forbid-long {
  /* overflow-x: hidden; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  line-break: anywhere;
}