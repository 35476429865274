.photo-viewer {
  z-index: 9999;
  color: white;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: calc(100% + 100px);
  width: 100%;
  background-color: black;
  background: rgba(50, 50, 50, 0.5);
  backdrop-filter: blur(20px);
  transition: 0.5s ease-in;
}

.photo-viewer.hide {
  transition: 0.5s ease-out;
  display: none;
}

.photo-viewer .hidden {
  transition: 0.5s ease-in;
  display: none;
}

.photo-viewer input[type="button"] {
  border-radius: 0;
  background-color: transparent;
  color: white;
  /* border-bottom: 1px solid transparent; */
}

.photo-viewer input[type="button"]:hover {
  background-color: #333;
}

.photo-viewer input[type="button"]:not(:last-child) {
  border-right: 0;
}

.photo-viewer input[type="button"]:first-child {
  border-radius: 5px 0 0 0px;
}

.photo-viewer input[type="button"]:last-child {
  border-radius: 0 5px 0 0;
}

.photo-viewer input[type="button"]:only-child {
  border-radius: 5px 5px 0 0;
}

.photo-viewer .media-frame {
  border: 1px solid #fff;
  background-color: white;
  display: block;
}

.photo-viewer .media-frame video {
  width: 100%;
  max-width: 1024px;
}

.photo-viewer .media-frame .comments {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.photo-viewer .media-frame img {
  min-width: 640px;
  max-width: 1024px;
  margin-bottom: 30px;
}

.photo-viewer .thumb-list {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: hidden;
  height: 140px;
  width: 100%;
  border: 1px solid #ccc;
  border-bottom: 0;
  border-radius: 5px 5px;
}

/* track */
.photo-viewer .thumb-list::-webkit-scrollbar {
  background-color: #999;
  border-radius: 5px;
}

/* Handle */
.photo-viewer .thumb-list::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
  transition: 0.3s ease-out;
}

.photo-viewer .thumb-list::-webkit-scrollbar-thumb:hover {
  background: maroon;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.photo-viewer .thumb {
  display: inline-block;
  height: 100px;
  width: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 1s ease;
  
}

.photo-viewer .thumb.video {
  display: inline-block;
   font-size: 5em;
   text-align: center;
   border: 1px solid #666;
}

.photo-viewer .thumb:hover {
  border: 2px solid white;
  transition: all 0.3s ease-in;
  box-shadow: 0px 0px 30px 10px #ccc;
}

.photo-viewer .thumb.selected {
  box-shadow: 0px 0px 30px 10px white;
  border: 2px solid white;
}

.photo-viewer .close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
  cursor: pointer;  
}

/* over-ride */
@media screen and (max-width: 1024px) {
  .photo-viewer .flex {
    display: block;
    width: 100%;
  }

  .photo-viewer .flex div.comments {
    display: block;
    width: 100% !important;
  }

  .photo-viewer .flex .one-4th,
  .flex .one-half,
  .flex .one-3rd,
  .flex .quarter {
    display: block;
    width: 100% !important;
  }

  /* .photo-viewer .pad-xl,
  .pad-lg {
    padding: 15px;
  } */
}
