.course-mgt {
  display: block;
}

.course-mgt select {
  display: block;
  background-color: #333;
  width: 100%;
  box-sizing: border-box;
  color: white;
  font-family: "Montserrat";
  padding: 10px;
  border-radius: 4px;
  font-size: 1.25em;
}

.course-mgt ul.toolbar {
  list-style: none;
  display: inline-block;
}

.course-mgt ul.toolbar li {
  display: inline-block;
}
