.date-range {
  display: block;
}

.date-range select {
  width: auto !important;  
  padding: 5px;
  font-family: "Montserrat";
}

.date-range select:first-child {
  border-radius: 5px 0 0 5px;
  border-right: 0;
}

.date-range select:nth-child(2) {
  border-radius: 0px 5px 5px 0;
  border-left: 0;
}
