.footer {
  background-color: #333;
  color: white;
  min-height: 280px;
}

.footer a:link,
.footer a:visited {
  color: white;
  border-bottom: 1px solid #ccc;
}
