.section-expander {
  display: block;
  min-width: 50px;
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 40px;
  box-shadow: 5px 5px 10px #f1f1f1;
}

.section-expander hr {
  margin: 0px 0 !important;
}

.section-expander h1,
.section-expander h2,
.section-expander h3 {
  margin: 0 !important;
  margin-top: 8px !important;
}

.section-expander > .pad {
  padding: 15px;
}

@media screen and (max-width: 640px) {
  .section-expander > .pad {
    padding: 10px;
  }
}

.section-expander .header {
  background-color: #fcfcfc;
}

.section-expander .title-bar {
  position: relative;
  top: -4px;
}

.section-expander .flex-still {
  display: flex;
}

.section-expander .exp-cont {
  position: relative;
  font-size: 1.5em;
  height: 25px;
  right: 5px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.section-expander .exp-cont:hover {
  color: maroon;
}

/* .section-expander .flex {
  display: flex;
  align-items: center;
}

.section-expander .flex :last-child {
  margin-left: auto;
} */

.section-expander .caption {
  font-size: 0.95em;
}

.section-expander .body {
  height: auto;
}

.section-expander .body.hidden {
  display: none;
  /* height: 0;
  overflow: hidden; */
}

@media screen and (max-width: 740px) {
  .section-expander {
    display: inline-block;
  }
}
