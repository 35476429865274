.feeling-panel {
  z-index: 9999;
  opacity: 1;
  display: inline-block;
  position: relative;
  transition: 0.3s ease-in;
}

.feeling-panel .hidden {
  opacity: 0;
  z-index: -9999;
  transition: 0.3s ease-in;
}

.feeling-panel button {
  font-size: 1.75em;
  padding: 0px 10px;
  background-color: #669900;
  position: relative;
  /* top: 5px; */
  bottom: -10px;
  border-radius: 5px 5px 0 0;
  font-family: Montserrat;
  transition: 0.3s ease-in-out;
}

.feeling-panel button span:last-child {
  position: relative;
  top: -4px;
}

.feeling-panel button:hover {
  background-color: #339900;
  transition: 0.3s ease-in-out;
}

.feeling-panel button span {
  position: relative;
  top: -3px;
}

.feeling-panel .feelings {
  z-index: 9999;
  opacity: 1;
  position: absolute;
  text-align: left;
  height: auto;
  width: 390px;
  left: 0;
  top: 0px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  line-height: 2em;
  /* box-shadow: 2px 2px 20px #999; */
  transition: 0.3s ease-in-out;
}

.feeling-panel .close {
  font-size: 1.75em;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.feeling-panel .close:hover {
  color: orangered;
  transition: 0.3s ease-in-out;
}

.feeling-panel li .menu {
  transition: 0.3s ease-in-out;
  border-radius: 3px;
}
.feeling-panel li .menu:hover {
  background-color: #669900;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
