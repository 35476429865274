.job-list table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.job-list table thead tr td {
  padding: 15px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #c1c1c1;
  vertical-align: top;
  font-weight: bold;
}

.job-list table tbody tr td {
  padding: 10px;
  border: 1px solid #c1c1c1;
}

.job-list table tbody tr:hover td {
  border-bottom: 1px solid maroon;
  transition: 0.3s ease-in-out;
}

.job-list select {
  width: 550px !important;
  /* max-width: 275px !important; */
}

@media screen and (max-width: 640px) {
  .job-list select {
    width: 275px;
  }
}
