/* @font-face {
  font-family: "Spinnaker Regular";
  src: url("/src/Lib/Fonts/Quicksand-Medium.ttf"); 
  src: url("/src/Lib/Fonts/Spinnaker-Regular.ttf");
} */

/* @import url("https://fonts.googleapis.com/css2?family=Spinnaker&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Karla&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Sintony&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

html,
body {
  margin: 0;
  color: #555;
  height: 100%;
  width: 100%;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: "Roboto Mono", monospace;
  letter-spacing: 3px;
}

.code2 {
  font-family: "Roboto Mono", monospace;
  letter-spacing: 3px;
}

/*Change text in autofill textbox*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
/* p {
  font-size: medium;
} */

h1,
h2,
h3 {
  color: #333;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 5px;
}

hr {
  border: 0;
  border-bottom: 1px solid #ddd;
}

ul {
  margin: 0;
  padding: 0;
}

ul.no-bullet {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  width: 95%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: x-large;
  outline: none;
}

input[type="text"],
input[type="password"] {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-family: "Montserrat", "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  font-size: medium;
  margin-bottom: 10px;
  margin-top: 10px;
  /* min-width: 320px;
  max-width: 100%; */
  outline: none;
  padding: 5px 12px;
}

input[type="text"].code {
  font-family: "Roboto Mono", monospace;
}

input[type="text"].short {
  min-width: 80px;
  max-width: 140px;
  box-sizing: border-box;
}

input[type="text"].long {
  width: 90%;
  max-width: 90%;
  box-sizing: border-box;
}

input[type="text"].otp-code {
  font-family: "Roboto Mono", monospace;
  min-width: 120px !important;
  width: 120px !important;
  max-width: 120px !important;
}

input[type="button"] {
  border-radius: 5px;
  font-family: "Montserrat", "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border: 0;
  padding: 8px 15px;
  color: white;
  background-color: #555;
  cursor: pointer;
  transition: 0.5s ease-out;
  margin: 0;
  outline: 0;
}

button:hover,
input[type="button"]:hover {
  background-color: #000;
  color: white;
  transition: 0.2s ease-in;
}

button {
  border-radius: 5px;
  font-family: Montserrat, "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  /* letter-spacing: 0.1em; */
  text-transform: uppercase;
  border: 0;
  padding: 10px 10px 8px 10px;
  color: white;
  background-color: #555;
  cursor: pointer;
  transition: 0.5s ease-out;
  margin: 0;
  outline: 0;
}

select {
  font-family: "Montserrat", "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  max-width: 540px;
  font-size: medium;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 5px;
}

@media screen and (max-width: 640px) {
  select {
    max-width: 100%;
    box-sizing: border-box;
  }
}

/*============================================== */

.break-words {
  word-break: break-all;
}

.bordered {
  border: 1px solid #ccc;
}

.radiused4 {
  border-radius: 10px;
}

.flex {
  display: flex;
  width: 100%;
}

.flex.nowrap {
  flex-flow: row;
  flex-wrap: nowrap;
}

.flex.justify {
  justify-content: space-between;
}

.flex.a-end {
  justify-content: flex-end;
}

.flex.l-start {
  justify-content: flex-start;
}

.flex.v-center {
  align-items: center; /* vertically */
}

.flex .eq {
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
}

.flex.p-center {
  align-items: center; /* vertically */
  justify-content: center; /* horizontally */
}

.flex .flex-child:last-child {
  margin-left: auto;
}

.flex .grow {
  flex-grow: 1;
}

.flex .last {
  margin-left: auto;
}

.flex.wrap {
  flex-wrap: wrap;
}
.flex .full-width {
  width: 95%;
}

.flex .auto-width {
  flex-grow: 1;
}

.flex .three-4th {
  width: 64.5%;
}

.flex .one-half {
  width: 49%;
}

.flex .one-3rd {
  width: 33%;
}

.flex .one-4th,
.flex .quarter {
  width: 24%;
}

@media screen and (max-width: 840px) {
  .flex {
    display: block;
    flex-wrap: wrap;
  }

  .flex.still {
    display: flex;
    flex-wrap: nowrap;
  }

  .flex.nowrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .flex .one-3rd {
    width: 100%;
  }
  .flex .one-half {
    width: 100%;
  }

  .flex .three-4th {
    width: 100%;
  }

  .flex .one-4th,
  .flex .quarter {
    width: 100%;
  }
}

.float-r {
  float: right;
}

.float-l {
  float: left;
}

/* paddings */

.loose {
  letter-spacing: 5px;
}
.pad-sm {
  padding: 5px;
}

.pad-md {
  padding: 10px;
}

.pad-lg {
  padding: 20px;
}

.pad-xl {
  padding: 40px;
}

.tb-pad-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tb-pad-md {
  padding-top: 15px;
  padding-bottom: 15px;
}

.tb-pad-xl {
  padding-top: 30px;
  padding-bottom: 30px;
}

.tb-pad-lg {
  padding-top: 20px;
  padding-bottom: 20px;
}

.b-pad-sm {
  padding-bottom: 10px;
}

.b-pad-md {
  margin-bottom: 15px;
}

.b-pad-lg {
  margin-bottom: 20px;
}

.b-pad-xl {
  margin-bottom: 30px;
}

.r-pad-sm {
  padding-right: 10px;
}
.r-pad-md {
  padding-right: 15px;
}
.r-pad-lg {
  padding-right: 20px;
}

.r-pad-xl {
  padding-right: 40px;
}

.l-pad-sm {
  padding-left: 10px;
}
.l-pad-md {
  padding-left: 15px;
}
.l-pad-lg {
  padding-left: 20px;
}

.b-pad-lg {
  padding-bottom: 20px;
}

.l-pad-xl {
  margin-left: 40px;
}

.lr-pad-sm {
  padding-left: 10px;
  padding-right: 10px;
}

.lr-pad-lg {
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 640px) {
  .pad-lg,
  .pad-xl {
    padding: 15px;
  }

  .tb-pad-sm {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/* alignments */

.a-left {
  text-align: left;
}

.a-right {
  text-align: right;
}

.a-center {
  text-align: center;
}

/* menus */

.burger-button {
  font-size: 1.75em;
  color: #999;
}

/* spacing */

.kern-logo {
  padding: 1px 4px 2px 2px;
  margin-bottom: 10px;
  font-size: large;
  background-color: maroon;
  display: inline-block;
  color: white;
}

/* modifiers */
.hashtag a:link,
.hashtag a:visited {
  border-bottom: 0 !important;
}
.hash-tag {
  background-color: #e8ebff;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  padding: 0 4px;
  border-radius: 5px;
  border-bottom: 0 !important;
}

.hash-tag:hover {
  background-color: #f0e99e;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

a:link .hash-tag,
a:visited .hash-tag {
  text-decoration: none !important;
  border-bottom: 0 !important;
}

.group {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.group input[type="radio"] {
  position: relative;
  top: 3px;
}

.image-edit {
  transition: 0.3s ease-out;
  /* background: url("/images/pencil.png") no-repeat top right; */
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.image-edit:hover {
  transition: 0.3s ease-in;
  opacity: 1;
}

.no-touch,
.nt {
  pointer-events: none;
  -ms-opacity: 0.4;
  opacity: 0.4;
}

.all-caps {
  text-transform: uppercase;
}
.bold {
  font-weight: bold;
}

.underline {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.font-lg {
  font-size: 1.25em;
}

.font-xl {
  font-size: 1.5em;
}

.font-xxl {
  font-size: 2em;
}

.font-norm {
  font-size: 0.85em;
}

.font-sm {
  font-size: 0.95em;
}

.font-smaller {
  font-size: small;
}

.font-xsmall {
  font-size: x-small;
}

.font-xm {
  font-size: small;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.line-under {
  border-bottom: 1px solid maroon;
  padding-bottom: 5px;
  margin-right: 30px;
  font-weight: bold;
  text-align: center;
}

.b-border-gray {
  border-bottom: 1px solid #ccc;
}

.b-border-white {
  border-bottom: 1px solid #fff;
}

.q {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 1.25em;
  display: inline-block;
  line-height: 0.9em;
}

/* colors and displays */

.color-theme {
  color: maroon !important;
}

.color-orange {
  color: #ff6600 !important;
}

.color-maroon {
  color: maroon !important;
}

.color-red {
  color: red !important;
}

.color-black {
  color: black !important;
}

.color-blue {
  color: steelblue !important;
}

.color-blue2 {
  color: #26a0fe !important;
}

.color-white {
  color: white !important;
}

.color-gray {
  color: #ccc !important;
}

.color-gray2 {
  color: #999 !important;
}

.color-mid-gray {
  color: #999 !important;
}

.color-green {
  color: #72a159 !important;
}

.color-purple {
  color: purple !important;
}

.color-pink {
  color: #ed195a;
}

.color-yellow {
  color: darkgoldenrod;
}

a:link,
a:visited {
  color: black;
  text-decoration: none;
}

/* global styles */

.display-block {
  display: block;
  clear: both;
  width: 100%;
}

.display-none,
.dn {
  display: none !important;
  transition: 0.3s ease-in;
}

.hidden {
  display: none;
}

.inline-block {
  display: inline-block;
}

.hand {
  cursor: pointer;
}

.excel-upload {
  /* border: 1px solid #ccc; */
  display: inline-block;
  border-radius: 4px;
  padding: 5px 15px 10px 15px;
  cursor: pointer;
  background-color: #666;
  color: white;
  transition: 0.3s ease-in-out;
}

.excel-upload:hover {
  transition: 0.3s ease-in-out;
  background-color: #333;
}

/* overrides */
.fa.fa-menu-item {
  margin-right: 25px;
}

.fa.fa-mi {
  margin-right: 10px;
}

/* decos */

/* animations */

@keyframes falling {
  0% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
  }
}

.downloading.animated,
.downloading.animated-hover:hover,
.faa-parent.animated-hover:hover > .downloading {
  -webkit-animation: falling 2s linear infinite;
  animation: falling 2s linear infinite;
}

.downloading.animated.fast,
.downloading.animated-hover.fast:hover,
.faa-parent.animated-hover:hover > .downloading.fast {
  -webkit-animation: falling 1s linear infinite;
  animation: falling 1s linear infinite;
}

.downloading.animated.faa-slow,
.downloading.animated-hover.faa-slow:hover,
.faa-parent.animated-hover:hover > .downloading.faa-slow {
  -webkit-animation: falling 3s linear infinite;
  animation: falling 3s linear infinite;
}

@keyframes impulse {
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

.impulse {
  animation: impulse 1s infinite;
}

.impulse.slow {
  animation: impulse 2s infinite;
}

@keyframes ascending {
  0% {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }
}

.uploading.animated,
.uploading.animated-hover:hover {
  -webkit-animation: ascending 2s linear infinite;
  animation: ascending 2s linear infinite;
}

.uploading.animated.fast,
.uploading.animated-hover.fast:hover {
  -webkit-animation: ascending 1s linear infinite;
  animation: ascending 1s linear infinite;
}

.uploading.animated.slow,
.uploading.animated-hover.faa-slow:hover {
  -webkit-animation: ascending 3s linear infinite;
  animation: ascending 3s linear infinite;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

.icon-spin.slow {
  -webkit-animation: icon-spin 5s infinite linear;
  animation: icon-spin 5s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* border animations */
