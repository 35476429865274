@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
/* @font-face {
  font-family: "Spinnaker Regular";
  src: url("/src/Lib/Fonts/Quicksand-Medium.ttf"); 
  src: url("/src/Lib/Fonts/Spinnaker-Regular.ttf");
} */

/* @import url("https://fonts.googleapis.com/css2?family=Spinnaker&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Karla&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Sintony&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap'); */

html,
body {
  margin: 0;
  color: #555;
  height: 100%;
  width: 100%;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: "Roboto Mono", monospace;
  letter-spacing: 3px;
}

.code2 {
  font-family: "Roboto Mono", monospace;
  letter-spacing: 3px;
}

/*Change text in autofill textbox*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
/* p {
  font-size: medium;
} */

h1,
h2,
h3 {
  color: #333;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 5px;
}

hr {
  border: 0;
  border-bottom: 1px solid #ddd;
}

ul {
  margin: 0;
  padding: 0;
}

ul.no-bullet {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  width: 95%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: x-large;
  outline: none;
}

input[type="text"],
input[type="password"] {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-family: "Montserrat", "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  font-size: medium;
  margin-bottom: 10px;
  margin-top: 10px;
  /* min-width: 320px;
  max-width: 100%; */
  outline: none;
  padding: 5px 12px;
}

input[type="text"].code {
  font-family: "Roboto Mono", monospace;
}

input[type="text"].short {
  min-width: 80px;
  max-width: 140px;
  box-sizing: border-box;
}

input[type="text"].long {
  width: 90%;
  max-width: 90%;
  box-sizing: border-box;
}

input[type="text"].otp-code {
  font-family: "Roboto Mono", monospace;
  min-width: 120px !important;
  width: 120px !important;
  max-width: 120px !important;
}

input[type="button"] {
  border-radius: 5px;
  font-family: "Montserrat", "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border: 0;
  padding: 8px 15px;
  color: white;
  background-color: #555;
  cursor: pointer;
  transition: 0.5s ease-out;
  margin: 0;
  outline: 0;
}

button:hover,
input[type="button"]:hover {
  background-color: #000;
  color: white;
  transition: 0.2s ease-in;
}

button {
  border-radius: 5px;
  font-family: Montserrat, "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  /* letter-spacing: 0.1em; */
  text-transform: uppercase;
  border: 0;
  padding: 10px 10px 8px 10px;
  color: white;
  background-color: #555;
  cursor: pointer;
  transition: 0.5s ease-out;
  margin: 0;
  outline: 0;
}

select {
  font-family: "Montserrat", "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  max-width: 540px;
  font-size: medium;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 5px;
}

@media screen and (max-width: 640px) {
  select {
    max-width: 100%;
    box-sizing: border-box;
  }
}

/*============================================== */

.break-words {
  word-break: break-all;
}

.bordered {
  border: 1px solid #ccc;
}

.radiused4 {
  border-radius: 10px;
}

.flex {
  display: flex;
  width: 100%;
}

.flex.nowrap {
  flex-flow: row;
  flex-wrap: nowrap;
}

.flex.justify {
  justify-content: space-between;
}

.flex.a-end {
  justify-content: flex-end;
}

.flex.l-start {
  justify-content: flex-start;
}

.flex.v-center {
  align-items: center; /* vertically */
}

.flex .eq {
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
}

.flex.p-center {
  align-items: center; /* vertically */
  justify-content: center; /* horizontally */
}

.flex .flex-child:last-child {
  margin-left: auto;
}

.flex .grow {
  flex-grow: 1;
}

.flex .last {
  margin-left: auto;
}

.flex.wrap {
  flex-wrap: wrap;
}
.flex .full-width {
  width: 95%;
}

.flex .auto-width {
  flex-grow: 1;
}

.flex .three-4th {
  width: 64.5%;
}

.flex .one-half {
  width: 49%;
}

.flex .one-3rd {
  width: 33%;
}

.flex .one-4th,
.flex .quarter {
  width: 24%;
}

@media screen and (max-width: 840px) {
  .flex {
    display: block;
    flex-wrap: wrap;
  }

  .flex.still {
    display: flex;
    flex-wrap: nowrap;
  }

  .flex.nowrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .flex .one-3rd {
    width: 100%;
  }
  .flex .one-half {
    width: 100%;
  }

  .flex .three-4th {
    width: 100%;
  }

  .flex .one-4th,
  .flex .quarter {
    width: 100%;
  }
}

.float-r {
  float: right;
}

.float-l {
  float: left;
}

/* paddings */

.loose {
  letter-spacing: 5px;
}
.pad-sm {
  padding: 5px;
}

.pad-md {
  padding: 10px;
}

.pad-lg {
  padding: 20px;
}

.pad-xl {
  padding: 40px;
}

.tb-pad-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tb-pad-md {
  padding-top: 15px;
  padding-bottom: 15px;
}

.tb-pad-xl {
  padding-top: 30px;
  padding-bottom: 30px;
}

.tb-pad-lg {
  padding-top: 20px;
  padding-bottom: 20px;
}

.b-pad-sm {
  padding-bottom: 10px;
}

.b-pad-md {
  margin-bottom: 15px;
}

.b-pad-lg {
  margin-bottom: 20px;
}

.b-pad-xl {
  margin-bottom: 30px;
}

.r-pad-sm {
  padding-right: 10px;
}
.r-pad-md {
  padding-right: 15px;
}
.r-pad-lg {
  padding-right: 20px;
}

.r-pad-xl {
  padding-right: 40px;
}

.l-pad-sm {
  padding-left: 10px;
}
.l-pad-md {
  padding-left: 15px;
}
.l-pad-lg {
  padding-left: 20px;
}

.b-pad-lg {
  padding-bottom: 20px;
}

.l-pad-xl {
  margin-left: 40px;
}

.lr-pad-sm {
  padding-left: 10px;
  padding-right: 10px;
}

.lr-pad-lg {
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 640px) {
  .pad-lg,
  .pad-xl {
    padding: 15px;
  }

  .tb-pad-sm {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/* alignments */

.a-left {
  text-align: left;
}

.a-right {
  text-align: right;
}

.a-center {
  text-align: center;
}

/* menus */

.burger-button {
  font-size: 1.75em;
  color: #999;
}

/* spacing */

.kern-logo {
  padding: 1px 4px 2px 2px;
  margin-bottom: 10px;
  font-size: large;
  background-color: maroon;
  display: inline-block;
  color: white;
}

/* modifiers */
.hashtag a:link,
.hashtag a:visited {
  border-bottom: 0 !important;
}
.hash-tag {
  background-color: #e8ebff;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  padding: 0 4px;
  border-radius: 5px;
  border-bottom: 0 !important;
}

.hash-tag:hover {
  background-color: #f0e99e;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

a:link .hash-tag,
a:visited .hash-tag {
  text-decoration: none !important;
  border-bottom: 0 !important;
}

.group {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.group input[type="radio"] {
  position: relative;
  top: 3px;
}

.image-edit {
  transition: 0.3s ease-out;
  /* background: url("/images/pencil.png") no-repeat top right; */
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.image-edit:hover {
  transition: 0.3s ease-in;
  opacity: 1;
}

.no-touch,
.nt {
  pointer-events: none;
  -ms-opacity: 0.4;
  opacity: 0.4;
}

.all-caps {
  text-transform: uppercase;
}
.bold {
  font-weight: bold;
}

.underline {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.font-lg {
  font-size: 1.25em;
}

.font-xl {
  font-size: 1.5em;
}

.font-xxl {
  font-size: 2em;
}

.font-norm {
  font-size: 0.85em;
}

.font-sm {
  font-size: 0.95em;
}

.font-smaller {
  font-size: small;
}

.font-xsmall {
  font-size: x-small;
}

.font-xm {
  font-size: small;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.line-under {
  border-bottom: 1px solid maroon;
  padding-bottom: 5px;
  margin-right: 30px;
  font-weight: bold;
  text-align: center;
}

.b-border-gray {
  border-bottom: 1px solid #ccc;
}

.b-border-white {
  border-bottom: 1px solid #fff;
}

.q {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 1.25em;
  display: inline-block;
  line-height: 0.9em;
}

/* colors and displays */

.color-theme {
  color: maroon !important;
}

.color-orange {
  color: #ff6600 !important;
}

.color-maroon {
  color: maroon !important;
}

.color-red {
  color: red !important;
}

.color-black {
  color: black !important;
}

.color-blue {
  color: steelblue !important;
}

.color-blue2 {
  color: #26a0fe !important;
}

.color-white {
  color: white !important;
}

.color-gray {
  color: #ccc !important;
}

.color-gray2 {
  color: #999 !important;
}

.color-mid-gray {
  color: #999 !important;
}

.color-green {
  color: #72a159 !important;
}

.color-purple {
  color: purple !important;
}

.color-pink {
  color: #ed195a;
}

.color-yellow {
  color: darkgoldenrod;
}

a:link,
a:visited {
  color: black;
  text-decoration: none;
}

/* global styles */

.display-block {
  display: block;
  clear: both;
  width: 100%;
}

.display-none,
.dn {
  display: none !important;
  transition: 0.3s ease-in;
}

.hidden {
  display: none;
}

.inline-block {
  display: inline-block;
}

.hand {
  cursor: pointer;
}

.excel-upload {
  /* border: 1px solid #ccc; */
  display: inline-block;
  border-radius: 4px;
  padding: 5px 15px 10px 15px;
  cursor: pointer;
  background-color: #666;
  color: white;
  transition: 0.3s ease-in-out;
}

.excel-upload:hover {
  transition: 0.3s ease-in-out;
  background-color: #333;
}

/* overrides */
.fa.fa-menu-item {
  margin-right: 25px;
}

.fa.fa-mi {
  margin-right: 10px;
}

/* decos */

/* animations */

@-webkit-keyframes falling {
  0% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
  }
}

@keyframes falling {
  0% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
  }
}

.downloading.animated,
.downloading.animated-hover:hover,
.faa-parent.animated-hover:hover > .downloading {
  -webkit-animation: falling 2s linear infinite;
  animation: falling 2s linear infinite;
}

.downloading.animated.fast,
.downloading.animated-hover.fast:hover,
.faa-parent.animated-hover:hover > .downloading.fast {
  -webkit-animation: falling 1s linear infinite;
  animation: falling 1s linear infinite;
}

.downloading.animated.faa-slow,
.downloading.animated-hover.faa-slow:hover,
.faa-parent.animated-hover:hover > .downloading.faa-slow {
  -webkit-animation: falling 3s linear infinite;
  animation: falling 3s linear infinite;
}

@-webkit-keyframes impulse {
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

@keyframes impulse {
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

.impulse {
  -webkit-animation: impulse 1s infinite;
          animation: impulse 1s infinite;
}

.impulse.slow {
  -webkit-animation: impulse 2s infinite;
          animation: impulse 2s infinite;
}

@-webkit-keyframes ascending {
  0% {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }
}

@keyframes ascending {
  0% {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }
}

.uploading.animated,
.uploading.animated-hover:hover {
  -webkit-animation: ascending 2s linear infinite;
  animation: ascending 2s linear infinite;
}

.uploading.animated.fast,
.uploading.animated-hover.fast:hover {
  -webkit-animation: ascending 1s linear infinite;
  animation: ascending 1s linear infinite;
}

.uploading.animated.slow,
.uploading.animated-hover.faa-slow:hover {
  -webkit-animation: ascending 3s linear infinite;
  animation: ascending 3s linear infinite;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

.icon-spin.slow {
  -webkit-animation: icon-spin 5s infinite linear;
  animation: icon-spin 5s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* border animations */

.profile-header {
  height: auto;
  position: relative;
  top: -140px;
  transition: 0.3s ease-in-out;
  width: 300px;
  box-sizing: border-box;
  margin: 0;
  margin-bottom: -140px;
  /* word-break: break-all !important; */
  /* border: 1px solid #ccc; */
}

.profile-header.stick {
  position: fixed;
  top: 50px;
  bottom: unset;
  transition: 0.3s ease;
}

.profile-header.stick-b {
  bottom: 320px;
  position: fixed;
  top: unset;
  transition: 0.3s ease;
}

.profile-header a:link,
.profile-header a:visited {
  color: steelblue !important;
}

.profile-header .mention {
  color: mediumseagreen;
  text-transform: lowercase;
}

.profile-header a:hover {
  border-bottom: 1px solid steelblue;
}

@media screen and (max-width: 840px) {
  .profile-header {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: -120px;
    margin-left: 5px;
    overflow-y: hidden;
    position: relative;
    width: 100%;
  }

  .profile-header .lead-head {
    /* position: relative; */
    display: block;
  }
}

.profile-header ul {
  line-height: 2em;
  list-style: none;
  margin: 0;
  padding: 0;
}

.profile-header textarea {
  border-radius: 20px;
  font-size: large !important;
}

.line-h {
  line-height: 2em;
}

.name-plate {
  text-transform: uppercase;
  color: black;
  font-size: 1.25em;
  font-weight: 800;
}
.connection-block {
  border-left: 5px solid #ccc;
  padding-left: 10px;
}
.avatar {
  background-color: #666;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  border: 3px solid #fff;
  height: 150px;
  width: 150px;
}

.role-block {
  background-color: maroon;
  border-radius: 50%;
  /* border: 1px solid white; */
  bottom: -40%;
  color: white;
  font-size: 1.2em;
  height: 35px;
  width: 34px;
  position: relative;
  right: -90%;
  text-align: center;
  font-size: 1.3em;
}

.role-block.admin {
  background-color: maroon;
  color: white;
  border: 1px solid #ccc;
  line-height: 30px;
  font-size: 1.5em;
}

.role-block.dev {
  position: relative;
  background-color: maroon;
  color: white;
  line-height: 30px;
  border: 2px solid #ccc;
}

.role-block.uni {
  background-color: maroon;
  border: 1px solid #ccc;
  color: white;
  font-size: 1em;
}

.profile-header .case-report {
  position: relative;
  bottom: 25px;
  color: #ff6600;
  cursor: pointer;
}

.profile-header .case-report:hover {
  color: red;
}

.social-bar {
  display: block;
}

.social-bar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  line-height: 40px;
  border: 1px solid #f1f1f1;
  border-left: 0;
  border-right: 0;
}

.social-bar ul li {
  position: relative;
  padding-right: 10px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  transition: 0.3s ease-out;
  font-size: 1.75em;
}

.social-bar ul li.selected {
  color: maroon;
}

.social-bar ul li.admin {
  font-size: 1.75em;
}

.social-bar ul li.cntd {
}

.social-bar ul li.msg {
  line-height: 50px;
  top: -2px;
}

.social-bar ul li:hover {
  color: maroon;
  transition: 0.3s ease-in;
}

.social-bar ul li:not(:last-child) {
  margin-right: 10px;
}

.social-bar hr.d {
   margin: 0px 0;
}
.modal {
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background-color: #333;
  background-color: rgba(50, 50, 50, 0.1);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: 0.3s ease-in;
  width: 100%;
  z-index: 9998;
}

.hide-modal {
  transition: 0.3s ease-out;
  /* display: none; */
  opacity: 0;
  z-index: -10;
  /* top: -100%;   */
}

.hide-dialog {
  transition: 0.3s ease-out;
  display: none;
  z-index: -1000;
  /* top: -100%;   */
}

/* ============================================================= */

.dialog-box {
  color: white;
  transition: 0.3s ease-in;
  min-height: 50px;
  width: 480px;
  max-width: 88%;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 30%;

  background-color: #333;
  background-color: rgba(60, 60, 60, 0.5);
  border: 1px solid #ccc;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 0px 0px 30px 10px #333;
  border-radius: 0px 0px 10px 10px;

  -webkit-transform: translateY(-30%);

          transform: translateY(-30%);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media screen and (max-width: 640px) {
  .dialog-box {
    left: 20px;
    width: 380px;
    -webkit-transform: translateY(-30%);
            transform: translateY(-30%);
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

.dialog-box .flex-d {
  display: flex;
  align-items: center;
}

.dialog-box hr.d {
  margin: 0 !important;
  border: 0;
  border-top: 1px solid #ccc;
}

.dialog-box .pad {
  padding: 10px;
}

.dialog-box .head {
  position: relative;
  /* top: 5px; */
  font-weight: bold;
  text-transform: uppercase;
  width: inherit;
  overflow: hidden;
}

.dialog-box .msg-area {
  position: relative;
  top: 3px;
  line-height: 1.5em;
  font-size: 0.85em;
}

/* .dialog-box hr {
  margin: 0;
  padding: 0;
  border: 0;
  border-bottom: 1px solid #ccc;
} */

.dialog-box .message-area {
  min-height: 100px;
}

.dialog-box textarea {
  width: 95%;
  padding: 5px;
  margin: 0;
  box-sizing: border-box;
  font-size: large;
  height: 80px;
}

.dialog-box input[type="button"] {
  /* margin-left: 5px; */
  background-color: rgba(30, 30, 30, 0.1);
  /* border: 1px solid #999; */
  color: white;
  cursor: pointer;
  font-family: "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  font-size: small;
  margin-top: 0px;
  outline: none;
  padding: 8px 10px;
  text-transform: uppercase;
  min-width: 80px;
}

.dialog-box input[type="button"]:first-child {
  border-radius: 5px 0 0 0;
}

.dialog-box input[type="button"]:last-child,
.dialog-box input[type="button"]:only-child {
  border-radius: 0 0px 5px 0;
}

.dialog-box input[type="button"]:not(:last-child) {
  border: 0;
  border-left: 1px solid #999;
}

.dialog-box input[type="button"]:hover {
  background-color: #000;
}
/* .dialog-box input[type="button"]:only-child {
  border-radius: 5px;
} */

.dialog-box input[type="text"] {
  width: 350px;
  max-width: auto;
}

.dialog-box .progress-bar {
  height: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-color: white;
  border-radius: 4px;
}

.inline-messagebar {
  display: inline-block;
  /* border-radius: 20px;   */
  line-height: 1.25em;
  font-size: 0.85em;
  opacity: 1;
  transition: 0.3s;
  font-family: Montserrat, Verdana, Geneva, Tahoma, sans-serif;
  flex-grow: 1;
}

.inline-messagebar.hide {
  transition: 0.3s ease-in-out;
  /* border-radius: 0; */
  opacity: 0;
  /* font-size: 0em;   */
  overflow: hidden;
  display: none;
  /* height: 20px; */
  /* margin-left: -10px; */
}

.inline-messagebar input[type="button"] {
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 2px;
  padding: 3px 7px;
  letter-spacing: normal;
  background-color: #72a159;
  font-size: small;
  transition: 0.3s ease-in-out;
}

.inline-messagebar input[type="button"]:hover {
  background-color: green;
  transition: 0.3s ease-in-out;
}

.inline-messagebar .padflex {
  display: flex;
  position: relative;
  align-items: center;
  /* margin-top: -5px; */
  padding: 2px;
  padding-left: 5px;
}

.inline-messagebar .color-blue {
  color: steelblue;
}

.inline-messagebar .color-green {
  color: green;
}

.inline-messagebar .color-purple {
  color: purple;
}

.inline-messagebar .color-red {
  color: red;
}

.inline-messagebar .color-orange {
  color: #ff6600;
}

.hero-banner {
  /* margin-top: 60px; */
  display: flex;
  background-color: #333;
  background-position: center 65%;
  background-repeat: no-repeat;
  height: 350px;  
  background-size: cover;        
}



.paging-control {
    position: fixed;
    right: 0;
    bottom: 100px;
    background-color: black;
    color: white;
    width: 80px;
    height: 50px;
    padding: 3px;
}
.connections {
  transition: 0.5s;
  margin-top: 60px;
}

.avatar-block {
  min-width: 270px;
  max-width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline-block;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
  border: 1px solid #e1e1e1;
  font-size: 0.8em;
  text-transform: uppercase;
}

.avatar-block:hover {
  transition: 0.5s ease-in-out;
  border: 1px solid saddlebrown; 
}

.connections .f-count {
   border: 1px solid #333;
   border-radius: 10px;
   padding: 5px;
   min-width: 50px;
   display: inline-block;
}
.connections input[type="text"] {
  border-radius: 20px;
}

.connections button.go {
  padding: 6px 10px !important;
  border-radius: 0 5px 5px 0;
}

.connections input[type="text"].search {
  padding: 8px 8px !important;
  background-color: #f5f5f5;
  border: 1px solid #999;
  font-size: small;
  min-width: 225px;
  max-width: 100%;
  border-radius: 20px;
}

.content-block {
  min-height: 420px;
  width: 100%;
  display: block;
}

.content-block .menu-bar {
  display: block;
}

.content-block .menu-bar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content-block .menu-bar ul li {
  display: inline-block;
  margin-right: 20px;
}

.content-block .menu-bar ul li:last-child {  
  margin-right: 0;
}



.content-block .menu-bar ul li a {
  transition: 0.3s ease-out;
  display: inline-block;
  /* margin-right: 10px; */
}

.content-block .menu-bar ul li.active a {
  transition: 0.3s ease-in;
  background-color: maroon;
  color: white !important;
  padding: 5px;
  border-radius: 4px;
}

.footer {
  background-color: #333;
  color: white;
  min-height: 280px;
}

.footer a:link,
.footer a:visited {
  color: white;
  border-bottom: 1px solid #ccc;
}

/* containers */
/* below images are dummy or their locations will be coming from the database records. */

.body-container {
  /* color:white; */
  z-index: 10;
  width: 920px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  min-height: 100vh;
  margin-bottom: 100px;

  /* border: 1px solid #f1f1f1; */
  border-top: 0;
  border-radius: 0 0 15px 15px;
}

.body-container .page {
  z-index: 11;
  min-height: 1024px;
}

.post-box {
  min-height: 220px;
  /* border: 1px solid #ccc; */
  background-color: #fff;
  /* border-radius: 2px; */
}

.example-enter {
  opacity: 0;
}

.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-exit {
  opacity: 1;
}

.example-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.avatar2 {
  width: 40px;
  height: 40px;
  border: 1px solid #eee;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid saddlebrown;
  border-radius: 50%;
}


.post-block {
  line-height: 1.25em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 30px;
  /* box-shadow: 5px 5px 10px #f1f1f1;  */
}

.my-wall {
  display: block;
  width: 100%;
}

.my-wall ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.divider {
  border-bottom: 1px solid #f1f1f1;
}

.my-wall input[type="text"] {
  background-color: #fafafa;
  border-left: 5px solid #f1f1f1;
  border-radius: 5px;
  box-sizing: border-box;
  border: 0;
  color: #333;
  font-size: medium;
  padding: 10px 0 10px 5px;
  width: 98%;
  /* border-bottom: 1px solid #ccc; */
}

.my-wall .post-thumbs {
  margin-bottom: 10px;
}
.my-wall .post-thumbs img {
  width: auto;
  height: 60px;
  margin-right: 1px;
  cursor: pointer;
}

.my-wall .post-thumbs video {
  width: 100%;
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.my-wall video {
  outline: none;
  border: 1px solid #ccc;
}

.my-wall textarea {
  border-radius: 5px;
  padding: 10px 0 10px 5px;
  background-color: #fafafa;
  border: 0;
  box-sizing: border-box;
  border-left: 5px solid #f1f1f1;
  font-size: medium;
  font-family: "system-ui", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  width: 98%;
  /* border-bottom: 1px solid #ccc; */
}

.my-wall .post-body img {
  width: auto;
  max-width: 100%;
}

.my-wall .post-body a:link,
.my-wall .post-body a:visited {
  color: #2b579a;
  border-bottom: 1px solid steelblue;
}

.my-wall .post-icons .ico {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.my-wall .icons .ico:hover {
  color: steelblue;
  transition: 0.3s ease-in-out;
}
.my-wall .button-area input[type="button"] {
  border: 0;
}

.my-wall .button-area input[type="button"]:first-child {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid #ccc;
}

.my-wall .button-area input[type="button"]:last-child {
  border-radius: 0 5px 5px 0px;
  border-right: 0;
}

.my-wall .col-expand-btn {
  color: #666;
  transition: 0.3s ease;
  margin-top: 10px;
  /* float: right; */
}
.my-wall .col-expand-btn:hover {
  color: maroon;
  transition: 0.3s ease;
}

.my-wall .edit-post {
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#postEntry {
  height: 0;
  display: block;
  width: 100%;
  overflow: hidden;
  transition: 0.3s ease;
}

#postEntry.show {
  height: auto;
  transition: 0.3s ease;
  margin-bottom: 20px;
}



.comment-box {
  display: block;
  background-color: white;  
}

.comment-box a.fullname:link,
.comment-box a.fullname:visited {
  color: steelblue;
}

.comment-box a.fullname:hover {
  border-bottom: 1px solid steelblue;
}

.comment-box input[type="button"] {
  border-radius: 0 0 5px 5px;
  border: 1px solid #f1f1f1;
  margin-right: 10px;
  margin-top: -4px;
}

.comment-box input[type="text"] {
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #eee;
}

.comment-box .c-box {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.comment-box .msg-body {
  display: block;
}

.comment-box .mr-sm {
  margin-left: 10px
}

.comment-box .msg-body.scroll {
  display: block;
  overflow-y: auto;
  height: 540px;
}

.comment-box div.comment-entry {
  color: black;
  background-color: transparent;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #ddd;
  display: inline-block;
  margin: 0;
  max-width: 280px;
  min-height: 20px;
  outline: none;
  padding: 10px;
  position: relative;
  width: 80%;
}

.comment-box .avatar-comment {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-bottom: -15px;
  margin-right: 10px;
  /* max-width: 200px; */
  position: relative;
  width: 40px;
  word-wrap: break-word;
}
.comment-box ul {
  list-style: none;
}
.comment-box ul li {
  /* border: 1px solid #f1f1f1; */

  border-radius: 15px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.comment-box .user-comment {
  margin-top: 10px;
  background-color: #f9f9f9;
  border-radius: 0px 5px 20px 5px;
  border: 1px solid rgb(200, 228, 248);
  word-break: break-all;
  color: black !important;
}

.comment-box .my-comment {
  word-break: break-all;
  width: auto;
  margin-top: 10px;
  /* background-color: rgb(233, 238, 243); */
  background-color: #fff;
  border-radius: 0px 0px 5px 20px;
  border: 1px solid rgb(212, 205, 171);
  background-color: #f9f9f9;
}

.comment-box .reload:hover {
  transition: 0.3s;
  color: green !important;
  cursor: pointer;
}

/* 
.comment-box .show-more {
} */

.wall-photo {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 85%;
  max-width: 1366px;
  min-height: 75vh;
  left: 50%;
  top: 60px;
  -webkit-transform: translateY(-20%);
          transform: translateY(-20%);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: 0.3s ease-in-out;

  background-color: #333;
  background-color: rgb(30, 30, 30, 0.85);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);

  box-shadow: 15px 15px 30px #666;
}

.wall-photo.hidden {
  /* height: 0;
  overflow: hidden; */
  z-index: -9999;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.wall-photo .thumbs {
  height: 80px;
  width: 100%;
}

.wall-photo .thumbs .pics {
  cursor: pointer;
  height: 60px;
  width: 60px;
  display: inline-block;
  border: 1px solid #555;
  margin-right: 5px;
  transition: 0.3s ease-in-out;

  background-position: center center;
  background-size: cover;
  /* background-image: url("https://localhost:44372/cdn/1903822/albums/wall/hero-1903822.jpg?v=Hi5SX09Jw583"); */
}

.wall-photo .thumbs .pics:first-child {
  margin-left: 15px;
}

.wall-photo .thumbs .pics:hover {
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
}

.wall-photo .thumbs .pics.active {
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 0px 10px 1px #999;
}

.wall-photo .close {
  cursor: pointer;
  width: 48px;
  position: relative;
  top: -2px;
  text-align: center;
  /* border: 1px solid #ccc; */
  font-size: 2.25em;
  color: #999 !important;
  margin-right: 10px;
  transition: 0.3s ease-in-out;
}

.wall-photo .close:hover {
  color: white !important;
  transition: 0.3s ease-in-out;
}
.wall-photo .blow-up {
  background-size: cover;
  background-position: 75% center;
  height: 70vh;
  width: 100%;
  display: block;
  /* position: relative; */
}

@media screen and (max-width: 740px) {
  .wall-photo {
    width: 95%;
    /* height: 95%; */
    top: 25%;
    left: 5px;
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }

  .wall-photo .thumbs .pics {
    height: 50px;
    width: 50px;
  }
}

@media screen and (max-height: 740px) {
  .wall-photo {
    width: 98%;
    height: 100%;
    top: 5px;
    /* left: 0; */
    /* margin-left: auto;
    margin-right: auto; */
    /* transform: translateY(5%);
    transform: translateX(50%); */
  }

  .wall-photo .blow-up {
    height: 480px;
  }
}

@media screen and (max-height: 640px) {
  .wall-photo .blow-up {
    height: 320px;
  }
}

.reaction-panel {
  display: inline-block;
  position: relative;
}

.reaction-panel .close {
  font-size: 2em;
}

.reaction-panel .close:hover {
  cursor: pointer;
  color: red;
}
.reaction-panel button {
  background-color: white;
  color: black;
  font-size: 1em;
  border: 1px solid #ccc;
  padding: 0px 5px;
  letter-spacing: normal;
  border-radius: 10px;
}

.reaction-panel button span {
  color: steelblue;
  position: relative;
  /* font-size: 1.5em; */
  top: -3px;
}

.reaction-panel button:hover {
  background-color: #333;
  color: white !important;
}

.reaction-panel button:hover span {
  color: white !important;
}

.reaction-panel button.active {
  background-color: #333;
  color: white;
}

.reaction-panel .more {
  z-index: 9999;
  opacity: 1;
  position: absolute;
  text-align: left;
  height: auto;
  width: 390px;
  right: 0;
  top: 0px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  line-height: 2em;
  box-shadow: 2px 2px 20px #999;
  transition: 0.3s ease-in-out;
}

.reaction-panel .more.hidden {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.reaction-panel .more ul li {
  padding: 0 5px;
  transition: 0.3s ease-in-out;
}
.reaction-panel .more ul li .menu:hover {
  display: block;
  background-color: #ddd;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 5px;
}

.reaction-panel .emote {
  transition: 0.3s ease-in-out;
}

.reaction-panel .emote:hover {
  color: maroon;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.show-moretext {
  display: block;
}

.show-moretext .more {
  color: steelblue;
  cursor: pointer;
}

.show-moretext .more:hover {
  background-color: #e8ebff;
}

.show-moretext a:link,
.show-moretext a:visited {
  border-bottom: 0 !important;
}

.show-moretext a:hover {
  border-bottom: 0 !important;
  background-color: #e8ebff;
}

.show-moretext .forbid-long {
  /* overflow-x: hidden; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  line-break: anywhere;
}
.feeling-panel {
  z-index: 9999;
  opacity: 1;
  display: inline-block;
  position: relative;
  transition: 0.3s ease-in;
}

.feeling-panel .hidden {
  opacity: 0;
  z-index: -9999;
  transition: 0.3s ease-in;
}

.feeling-panel button {
  font-size: 1.75em;
  padding: 0px 10px;
  background-color: #669900;
  position: relative;
  /* top: 5px; */
  bottom: -10px;
  border-radius: 5px 5px 0 0;
  font-family: Montserrat;
  transition: 0.3s ease-in-out;
}

.feeling-panel button span:last-child {
  position: relative;
  top: -4px;
}

.feeling-panel button:hover {
  background-color: #339900;
  transition: 0.3s ease-in-out;
}

.feeling-panel button span {
  position: relative;
  top: -3px;
}

.feeling-panel .feelings {
  z-index: 9999;
  opacity: 1;
  position: absolute;
  text-align: left;
  height: auto;
  width: 390px;
  left: 0;
  top: 0px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  line-height: 2em;
  /* box-shadow: 2px 2px 20px #999; */
  transition: 0.3s ease-in-out;
}

.feeling-panel .close {
  font-size: 1.75em;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.feeling-panel .close:hover {
  color: orangered;
  transition: 0.3s ease-in-out;
}

.feeling-panel li .menu {
  transition: 0.3s ease-in-out;
  border-radius: 3px;
}
.feeling-panel li .menu:hover {
  background-color: #669900;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.feeds {
  display: block;
}

.feeds ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.feeds a:link,
.feeds a:visited {
  color: steelblue !important;
}

.feeds a:hover {
  color: maroon !important;
}

.feeds .avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-bottom: -15px;
  margin-right: 10px;
  max-width: 100%;
  position: relative;
  width: 40px;
  /* border: 1px solid #ccc; */
}

.feeds .post-thumbs {
  margin-bottom: 10px;
}
.feeds .post-thumbs img {
  width: auto;
  height: 75px;
  margin-right: 1px;
  cursor: pointer;
}

.feeds .post-thumbs video {
  width: 100%;
  max-width: 100%;
  height: auto;  
  cursor: pointer;
}
.photo-viewer {
  z-index: 9999;
  color: white;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: calc(100% + 100px);
  width: 100%;
  background-color: black;
  background: rgba(50, 50, 50, 0.5);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  transition: 0.5s ease-in;
}

.photo-viewer.hide {
  transition: 0.5s ease-out;
  display: none;
}

.photo-viewer .hidden {
  transition: 0.5s ease-in;
  display: none;
}

.photo-viewer input[type="button"] {
  border-radius: 0;
  background-color: transparent;
  color: white;
  /* border-bottom: 1px solid transparent; */
}

.photo-viewer input[type="button"]:hover {
  background-color: #333;
}

.photo-viewer input[type="button"]:not(:last-child) {
  border-right: 0;
}

.photo-viewer input[type="button"]:first-child {
  border-radius: 5px 0 0 0px;
}

.photo-viewer input[type="button"]:last-child {
  border-radius: 0 5px 0 0;
}

.photo-viewer input[type="button"]:only-child {
  border-radius: 5px 5px 0 0;
}

.photo-viewer .media-frame {
  border: 1px solid #fff;
  background-color: white;
  display: block;
}

.photo-viewer .media-frame video {
  width: 100%;
  max-width: 1024px;
}

.photo-viewer .media-frame .comments {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.photo-viewer .media-frame img {
  min-width: 640px;
  max-width: 1024px;
  margin-bottom: 30px;
}

.photo-viewer .thumb-list {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: hidden;
  height: 140px;
  width: 100%;
  border: 1px solid #ccc;
  border-bottom: 0;
  border-radius: 5px 5px;
}

/* track */
.photo-viewer .thumb-list::-webkit-scrollbar {
  background-color: #999;
  border-radius: 5px;
}

/* Handle */
.photo-viewer .thumb-list::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.photo-viewer .thumb-list::-webkit-scrollbar-thumb:hover {
  background: maroon;
  border-radius: 5px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.photo-viewer .thumb {
  display: inline-block;
  height: 100px;
  width: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 1s ease;
  
}

.photo-viewer .thumb.video {
  display: inline-block;
   font-size: 5em;
   text-align: center;
   border: 1px solid #666;
}

.photo-viewer .thumb:hover {
  border: 2px solid white;
  transition: all 0.3s ease-in;
  box-shadow: 0px 0px 30px 10px #ccc;
}

.photo-viewer .thumb.selected {
  box-shadow: 0px 0px 30px 10px white;
  border: 2px solid white;
}

.photo-viewer .close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
  cursor: pointer;  
}

/* over-ride */
@media screen and (max-width: 1024px) {
  .photo-viewer .flex {
    display: block;
    width: 100%;
  }

  .photo-viewer .flex div.comments {
    display: block;
    width: 100% !important;
  }

  .photo-viewer .flex .one-4th,
  .flex .one-half,
  .flex .one-3rd,
  .flex .quarter {
    display: block;
    width: 100% !important;
  }

  /* .photo-viewer .pad-xl,
  .pad-lg {
    padding: 15px;
  } */
}

/* .photo-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1px;
}

.photo-column {
  flex: 24%;
  max-width: 24%;
  padding: 0 3px;
} */

.photo-album {
  min-height: 180px;
  margin-right: 1%;
  flex-basis: 150px;
  margin-bottom: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  overflow: hidden;
  /* border: 1px solid #ccc; */

  object-fit: cover;
}

/* .photo-album img {
  object-fit: contain;
  cursor: pointer;
  height: 100px;
  width: auto;
  display: inline-block;
  background-color: white;
} */

.photo-album .thumb {
  height: 150px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  background-size: cover;
}
/* .flex .photo-album:last-child {
  margin-right: auto;
} */

.photo-album .crud .icon {
  color: #666 !important;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.photo-album .crud .icon:hover {
  color: steelblue !important;
  transition: 0.3s ease-in-out;
}

.photo-album .crud .icon:not(:last-child) {
  margin-right: 15px;
}

.photo-album button.pa {
  padding: 0px 10px !important;
  border-radius: 2px !important;
}

.photo-album button.pa span.cap {  
  position: relative;
  top: -4px;
}

.photo-album button.pa span.ix {  
  position: relative;
  top: -2px;
}

@media screen and (max-width: 640px) {
  .photo-album {
    flex: 100% 1;
    width: 100%;
    max-width: 100%;
    height: 320px;
    /* padding: 0 3px; */
  }
}

.section-expander {
  display: block;
  min-width: 50px;
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 40px;
  box-shadow: 5px 5px 10px #f1f1f1;
}

.section-expander hr {
  margin: 0px 0 !important;
}

.section-expander h1,
.section-expander h2,
.section-expander h3 {
  margin: 0 !important;
  margin-top: 8px !important;
}

.section-expander > .pad {
  padding: 15px;
}

@media screen and (max-width: 640px) {
  .section-expander > .pad {
    padding: 10px;
  }
}

.section-expander .header {
  background-color: #fcfcfc;
}

.section-expander .title-bar {
  position: relative;
  top: -4px;
}

.section-expander .flex-still {
  display: flex;
}

.section-expander .exp-cont {
  position: relative;
  font-size: 1.5em;
  height: 25px;
  right: 5px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.section-expander .exp-cont:hover {
  color: maroon;
}

/* .section-expander .flex {
  display: flex;
  align-items: center;
}

.section-expander .flex :last-child {
  margin-left: auto;
} */

.section-expander .caption {
  font-size: 0.95em;
}

.section-expander .body {
  height: auto;
}

.section-expander .body.hidden {
  display: none;
  /* height: 0;
  overflow: hidden; */
}

@media screen and (max-width: 740px) {
  .section-expander {
    display: inline-block;
  }
}

.about-me {
  display: block;
}

.about-me ul {
  list-style: none;
}

.about-me ul li:empty {
  margin-bottom: 40px;
}

.about-me select.month {
  border-radius: 5px 0px 0 5px;
  border-right: 0;
}

.about-me select.year {
  border-radius: 0 5px 5px 0;
}

.about-me div.ib {
  display: inline-block;
}

.about-me .remove-btn {
  transition: 0.3s ease-in;
}

.about-me .remove-btn:hover {
  color: red;
  transition: 0.3s ease-in-out;
}

.about-me .edit-btn {
  transition: 0.3s ease-in;
}

.about-me .edit-btn:hover {
  color: steelblue;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .about-me div.ib {
    display: block;
  }
}

.info-bar {
  color: #333;
  font-size: medium;
  margin-bottom: 20px;
  transition: 0.3s ease-in-out;
}

.info-bar hr {
  border: 0;
  border-top: 1px dashed #333;
  margin-top: 10px !important;
}

.info-bar .info-panel {
  height: 0;
  border-radius: 20px 0 15px 5px;
  background-color: lemonchiffon;
  font-family: Montserrat, Verdana, Geneva, Tahoma, sans-serif;
  /* transition: 0.5s ease; */
  overflow: hidden;
}

.info-bar .title {
  font-size: small;
  padding: 5px;
}

.info-bar .info-panel.show {
  height: auto;
  border-radius: 20px 0 15px 5px;
  /* transition: 0.5s ease; */
  /* border: 1px solid #ccc; */
  /* overflow-y: scroll;   */
}

.info-bar .info-body p {
  font-size: small;
}

.info-bar .icon-bar {
  background-color: lemonchiffon;
  border-radius: 4px 4px 0 0;
  bottom: -3px;
  color: #666;
  height: 28px;
  position: relative;
  text-align: center;
  transition: 0.5s ease-out;
  min-width: 32px;
  cursor: pointer;
}

.info-bar .icon-bar:hover {
  color: #ff6600;
  transition: 0.3s ease-in;
}

.info-bar .icon-bar.on {
  color: #ff6600;
  transition: 0.3s ease-in;
  /* border: 1px solid #ccc; */
  border-bottom: 0;
}

.info-bar .title-bar {
  display: flex;
  align-items: center;
}

.info-bar .title-bar :last-child {
  margin-left: auto;
  margin-bottom: 0px;
}

.info-bar .bulb {
  cursor: pointer;
  font-size: 1.5em;
  position: relative;
  top: -5px;
  padding-right: 5px;
}

.info-bar ul {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  list-style: square !important;
  list-style-type: square !important;
  /* font-size: small; */
  line-height: 2em;
}

body {
  background-color: white;
}

.login-page {
  background-color: #f5f5f5;
}

input[type="checkbox"].agree {
  height: 24px;
  width: 24px;
  position: relative;
  vertical-align: middle;
  bottom: 1px;
  margin-right: 10px;
}

.login-page .container {
  width: 940px;
  max-width: 100%;
  min-height: calc(100vh - 280px);
  margin-left: auto;
  margin-right: auto;
}

/* .container {
  width: 940px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
} */

.login-page .login-banner {
  min-height: 65vh;
  /* border-bottom: 1px solid #ccc; */
}

.login-page .login-box {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 5px 5px 10px #ccc;
  margin-bottom: 20px;
  margin-top: 20px;
}

.login-page .login-area {
  min-height: 25vh;
}

.login-page ul.login {
  list-style: none;
  margin: 0;
  padding: 0;
}

.login-page .fool-chrome {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
  display: inline-block;
}

.login-page .rcodes ul {
  margin-left: 5px;
  list-style: none;
  color: #999 !important;
}

/* .login-page ul li {
  line-height: 3em;
} */

.login-page ul.login a:link,
.login-page ul.login a:visited {
  color: steelblue;
}

.login-page ul.login a:hover {
  border-bottom: 1px solid steelblue;
}

.login-page ul.login li input[type="text"],
.login-page ul.login li input[type="password"] {
  font-size: large;
  line-height: large;
  padding: 10px;
  width: 99%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  font-family: "Montserrat", "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.login-page ul.login li input[type="text"].short,
.login-page ul.login li input[type="password"].short {
  min-width: 180px !important;
  width: 180px;
  max-width: 100%;
  /* font-weight: bold !important; */
}

.login-page ul.login li input[type="text"].user {
  width: 175px;
  border: 0;
  background-color: white;
  border-bottom: 2px solid #ccc;
  /* font-weight: bold !important; */
}

.login-page ul.login li input[type="text"].auth-code {
  font-family: "Roboto Mono", monospace;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  border-radius: 20px;
  font-size: large;
  text-align: center;
}

.login-page ul.login li input[type="submit"],
.login-page ul.login li input[type="button"] {
  transition: 0.5s;
  /* line-height: 3em; */
  font-size: large;
  padding: 15px 10px;
  width: 100%;
  background-color: tomato;
  color: white;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
}

.login-page ul.login li input[type="submit"]:hover {
  background-color: maroon;
}

.login-page ul.login li input[type="button"] {
  background-color: #66cc00;
  letter-spacing: 0px;
}

.login-page ul.login li input[type="button"]:hover {
  background-color: #119900;
}

.login-page ul.login select {
  width: 99%;
  box-sizing: border-box;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
}

.login-page button.google {
  border: 1px solid #ccc;
  outline: 0;
  padding: 5px 10px 10px 5px;
  width: auto;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-out;
  letter-spacing: normal;
}

.login-page button.google:hover {
  background-color: #e1e1e1;
}

.terms-condi {
  display: block;
}

.terms-condi ul {
  margin-left: 15px;
}

.date-picker {
  display: block;
}

.date-picker select {
  width: auto !important;
  padding: 10px !important;
  font-family: "Roboto Mono", "Tahoma", "Verdana";
  font-size: 1em !important;
  color: #666 !important;
}

.date-picker select:first-child {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid inherit !important;
}

.date-picker select:nth-child(2) {
  border-radius: 0;
  border-left: 0 !important;
  border-right: 0 !important;
}

.date-picker select:last-child {
  border-radius: 0px 5px 5px 0;
  border-left: 1px solid inherit !important;
}

.my-body-container {
  width: 99%;
  max-width: 99%;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.my-account .dn {
  display: none;
}

.my-account {
  display: block;
  margin-top: 70px;
}

.my-account hr {
  margin: 0;
  padding: 0;
}

.my-account .menu {
  /* border-right: 1px solid #eee; */
  flex-shrink: 0;
  width: 320px;
  max-width: 100%;
  transition: 0.3s ease-in-out;
}

.my-account .menu.hide {
  width: 120px !important;
  max-width: 120px !important;
  transition: 0.3s ease-in-out;
}

.my-account .menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.my-account .menu ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  /* width: 50px; */
  overflow-x: hidden;
}

.my-account .menu ul li a {
  /* line-height: 3em;   */
  transition: 0.3s ease-out;
}

.my-account .menu ul li.active a {
  /* background-color: maroon !important; */
  color: orangered !important;
  transition: 0.3s ease-in;
  /* border-left: 5px solid maroon; */
  /* padding-left: 1px; */
}

.my-account input[type="checkbox"] {
  width: 20px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.my-account label {
  position: relative;
  top: 2px;
  font-weight: 400;
  /* margin-bottom: 10px; */
}

.my-account ul.inline {
  list-style: none;
  margin: 0;
  padding: 0;
}

.my-account ul.inline li {
  display: inline-block;
}

.my-account table.aca-list {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.my-account table.aca-list thead {
  font-weight: bold;
}

.my-account table.aca-list thead tr td {
  padding: 20px !important;
  background-color: #f1f1f1;
}

.my-account table.aca-list thead tr td:last-child {
  width: 60px;
}

.my-account table.aca-list tr.active {
  background-color: #333;
  color: white;
}

.my-account table.aca-list tr td {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.my-account table.aca-list tbody tr:not(:last-child):hover {
  cursor: pointer;
  background-color: #ddd;
  transition: 0.3s ease-in-out;
  color: black;
}

.my-account table.course-list {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.my-account table.course-list thead tr td {
  background-color: #f1f1f1;
  padding: 20px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.my-account table.course-list thead tr td:first-child {
  text-align: center;
  width: 40px;
}

.my-account table.course-list thead tr td:nth-child(2) {
  width: 120px;
}

.my-account table.course-list thead tr td:last-child {
  width: 60px;
}

.my-account table.course-list tbody tr td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.my-account table.course-list tbody tr td:first-child {
  text-align: center;
}

/* ========================== */
.my-account .tool-icon {
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 10px;
  transition: 0.3s ease-in-out;
}

.my-account .tool-icon:hover {
  background-color: maroon;
  color: white;
}

/* .my-account table.course-list tr td:first-child {  
  width: 50px;
} */

.my-account input[type="password"] {
  border-radius: 15px;
  width: 280px !important;
  max-width: 100% !important;
}

.my-account input[type="text"].short,
.my-account input[type="password"].short {
  border-radius: 15px;
  width: 130px;
  max-width: 100%;
}

.my-account .fool-chrome {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
}

.my-account .fool-chrome input[type="password"],
.my-account .fool-chrome input[type="text"] {
  height: 0 !important;
  width: 0 !important;
  height: 0px;
  padding: 0;
  margin: 0;
  border: 0;
}

.my-account select {
  padding: 7px;
  width: 340px;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  font-family: "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1em;
}

/* */

.security-settings {
  position: relative;
  width: auto;
}

.security-settings ul.bullet {
  margin: auto;
  list-style: disc !important;
  list-style-type: disc !important;
}

/* .security-settings ul.info-bar li {
  line-height: 2em;
} */

.security-settings ul.qr-steps {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none !important;
}

.security-settings ul.qr-steps li:empty {
  display: block;
}

.security-settings ul.qr-steps li:empty {
  margin: 40px 0;
}

.security-settings .qr-code {
  font-family: "Roboto Mono", monospace;
}

.security-settings .qr-text {
  display: inline-block;
  background-color: #f5f5f5;
  width: auto;
  border-radius: 4px;
  margin: 10px 0;
}
.security-settings input[type="text"],
.security-settings input[type="password"] {
  font-family: "Roboto Mono", monospace;
  width: 250px !important;
  max-width: 100% !important;
}

.security-settings .qr-text input[type="text"] {
  font-family: "Roboto Mono", monospace;
  text-align: center;
  border: 0;
  border-bottom: 1px solid #333;
  border-radius: 0;
  min-width: 15px;
  width: 15px;
  max-width: 15px;
  background-color: transparent;
  margin: 0 7px 0 7px;
  font-size: x-large;
  font-weight: bold;
}

.security-settings .break {
  word-break: break-all;
}

.address-location ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.address-location ul li {
  margin: 20px 0 20px 0;
}

.users-migration ul.bullet {
  list-style: square !important;
  list-style-type: square !important;
  margin-left: 20px;
}

.users-migration ul.bullet li {
  display: list-item !important;
  list-style-type: square !important;
}

@media screen and (max-width: 740px) {
  .my-account .myl {
    padding: 0 !important;
    padding-left: 18px !important;
    margin: 0;
  }
  .my-body-container {
    width: 98%;
  }

  .my-account .menu {
    display: inline-block;
    width: 100%;
  }

  .my-account .section-expander {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }

  .my-account .menu .section-expander .header {
    display: none;
  }

  .my-account .menu ul {
    display: inline-block;
  }

  .my-account .menu .h {
    display: none;
  }

  .my-account .menu ul li {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 15px;
    display: inline-block;
    overflow-x: hidden;
  }

  .my-account table tr th,
  .my-account table tr td {
    width: auto !important;
  }
}

.my-case-reports {
  display: block;
}

.my-case-reports table.case {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.my-case-reports table.case thead tr th {
  padding: 20px !important;
  border: 1px solid #999;
  text-transform: uppercase;
}

.my-case-reports table.case tbody tr td {
  padding: 15px !important;
  border: 1px solid #999;
}

.my-case-reports table.case tbody tr.user {
  background-color: #f6dbdb;
}

.my-case-reports table.case tbody tr.system {
  background-color: #e9e5d5;
}

.my-case-reports .avatar {
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.job-list table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.job-list table thead tr td {
  padding: 15px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #c1c1c1;
  vertical-align: top;
  font-weight: bold;
}

.job-list table tbody tr td {
  padding: 10px;
  border: 1px solid #c1c1c1;
}

.job-list table tbody tr:hover td {
  border-bottom: 1px solid maroon;
  transition: 0.3s ease-in-out;
}

.job-list select {
  width: 550px !important;
  /* max-width: 275px !important; */
}

@media screen and (max-width: 640px) {
  .job-list select {
    width: 275px;
  }
}

.admin-tasks {
  display: block;
}

.admin-tasks a:link,
.admin-tasks a:visited {
  color: steelblue !important;
}

.admin-tasks a:hover {
  color: orangered !important;
}

.admin-tasks table.case-list {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.admin-tasks table.case-list thead tr th {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  text-align: left;
}
.admin-tasks table.case-list tbody tr td {
  padding: 5px;
  border: 1px solid #ccc;
}

.admin-tasks label {
  position: relative;
  top: -1px;
}

.admin-tasks .avatar {
  width: 48px;
  height: 48px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.admin-tasks button.refresh {
    padding: 4px 15px;
    margin-left: 15px;
}
.course-mgt {
  display: block;
}

.course-mgt select {
  display: block;
  background-color: #333;
  width: 100%;
  box-sizing: border-box;
  color: white;
  font-family: "Montserrat";
  padding: 10px;
  border-radius: 4px;
  font-size: 1.25em;
}

.course-mgt ul.toolbar {
  list-style: none;
  display: inline-block;
}

.course-mgt ul.toolbar li {
  display: inline-block;
}

.date-range {
  display: block;
}

.date-range select {
  width: auto !important;  
  padding: 5px;
  font-family: "Montserrat";
}

.date-range select:first-child {
  border-radius: 5px 0 0 5px;
  border-right: 0;
}

.date-range select:nth-child(2) {
  border-radius: 0px 5px 5px 0;
  border-left: 0;
}

.uni-broadcast {
  display: block;
  width: 300px;
  max-width: 100%;
}

.uni-broadcast a:link,
.uni-broadcast a:visited {
  color: steelblue;
  transition: 0.3s ease-in-out;
}

.uni-broadcast a:hover {
  border-bottom: 1px solid steelblue;
  transition: 0.3s ease-in-out;
}

.uni-broadcast ul {
  list-style: none;
}

.uni-broadcast .bracket {
  border-left: 3px solid #f1f1f1;
  padding-left: 15px;
}

/* .uni-broadcast ul li {
  border-left: 3px solid #ccc;
  padding-left: 10px;
} */

.uni-broadcast ul li:empty {
  border: 0;
  padding-left: 5px;
}

.uni-broadcast .post-thumbs img {
  width: auto;
  height: 40px;
  margin-right: 1px;
  cursor: pointer;
}

.uni-broadcast .post-thumbs video {
  width: 100%;
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

@media screen and (max-width: 840px) {
  .uni-broadcast {
    width: 100%;
  }
}

.main-nav {
  z-index: 1001;
  height: 55px;
  transition: 0.5s ease-in;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  color: white !important;
  border-bottom: 1px solid #333;
}

.main-nav .noti {
  transition: 0.3s ease-in-out;
  position: absolute;
  margin-left: -7px;
  margin-top: -3px;  
  color: red;  
  /* background-color: #fff;  */
  font-weight: bold;
  padding: 0 3px;
  /* border-radius: 5px; */
  font-size: 22px;    
  padding-top: -5px;  
}

.main-nav .menu ul {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  top: -5px;
}

.main-nav .menu ul li {
  color: white !important;
  display: inline-block;
  font-size: 1.5em;
  margin-right: 15px !important;
  padding: 0 12px 0 12px;
  transition: 0.3s ease;
  vertical-align: middle;
}
.main-nav .menu ul li:hover {
  transition: 0.3s;
  color: #ffcc00 !important;
}

.main-nav .menu li.log-off:hover {
  transition: 0.3s;
  color: #ffcc00 !important;
  border-radius: 50%;
}

.main-nav ul li a:link,
.main-nav a:visited {
  position: relative;
  color: #fff !important;
  transition: 0.3s;
}

.main-nav ul li a:hover {
  /* color: maroon !important; */
  color: #ffcc00 !important;
  transition: 0.3s;
}

/* .main-nav .menu ul li:last-child {
  display: inline-block;
  margin-left: 40px;
} */

.main-nav ul li.burger-bar {
  display: none !important;
  transition: 0.3s ease-out;
  cursor: pointer;
}

/* .main-nav input[type="text"] {
  width: 100px;
  max-width: 100px;
  padding: 5px;
  font-size: small;
  border-radius: 2px;
  border: 1px solid #fff;
} */

.main-nav .dekstop-menu {
  display: block;
  transition: 0.3s ease-in;
}

.main-nav .burger-btn {
  display: none !important;
  position: relative;
  width: 100%;
  text-align: right;
  font-size: 2.5em;
  top: -5px;
  right: 10px;
  transition: 0.3s ease-in-out;
}

.main-nav .burger-btn:hover {
  color: #ffcc00;
  transition: 0.3s ease-in-out;
}

.main-nav input[type="text"] {
  border-radius: 20px;
}

/*search*/

.main-nav .search-button {
  z-index: 1;
  position: absolute;
  margin-left: -15px;
  top: -6px;
  margin-top: 16px;
  cursor: pointer;
  color: #666;
}

.main-nav .search-panel {  
  border-radius: 10px;
  border: 1px solid #ccc;
  color: black;
  height: 0px;
  opacity: 0;  
  overflow-y: hidden;
  position: absolute;
  top: 45px;
  transition: 0.3s ease-in-out;  
  width: 320px;
  z-index: -100;
}

.main-nav .search-panel.shown {
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  color: white;
  background-color: rgba(30, 30, 30, 0.9);
  height: 180px;
  opacity: 1;
  overflow-y: auto;
  position: absolute;
  transition: 0.2s ease-in;
  z-index: 100;
  box-shadow: 0px 0px 30px 1px #ccc;
}

.main-nav .search-panel ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-nav .search-panel ul li {
  cursor: pointer;
  line-height: 2em;
  transition: 0.3s;
  padding: 4px;
}

.main-nav .search-panel ul li:hover {
  background-color: maroon;
  color: white;
  transition: 0.3s;
}

.main-nav .avatar-menu {
  background-color: #666;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid white;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  margin-right: 10px;
  max-width: 100%;
  position: relative;
  transition: 0.3s ease-out;
  vertical-align: middle;
  width: 35px;
  top: 3px;
}

.main-nav .avatar-menu:hover {
  border: 1px solid maroon;
  transition: 0.3s ease-in;
}

.main-nav .logo-search {
  transition: 0.3s ease-in-out;
}

/* mobile */

@media screen and (max-width: 740px) {
  .main-nav .logo-search {
    position: relative;
    transition: 0.3s ease-in-out;
    top: 5px;
  }

  .main-nav input[type="text"] {
    position: relative;
    top: 4px;
    min-width: 280px;
    width: 280px !important;
    max-width: 280px;
    box-sizing: border-box;
    border-radius: 20px;
  }

  .noti {
    color: white !important;
  }
  .main-nav .burger-btn {
    position: fixed;
    z-index: 100;
    top: 2px;
    right: 2px;
    width: 40px;
    /* border: 1px solid white; */
    display: inline-block !important;
    /* width: 100%; */
    text-align: right;
  }

  .main-nav .logo-mobile {
    position: relative;
    /* top: -14px; */
  }

  .main-nav .dekstop-menu {
    background-color: maroon;
    color: white;
    display: block;
    height: 40px;
    position: fixed;
    text-align: right;
    top: 0;
    transition: 0.3s ease-in;
    width: 100%;
    z-index: 101;
  }

  .main-nav .dekstop-menu ul {
    margin: 0;
  }

  .main-nav .dekstop-menu.shown {
    top: -100px;
    transition: 0.3s ease-in;
  }

  .main-nav .dekstop-menu a:link,
  .main-nav .dekstop-menu a:visited {
    color: white !important;
    text-align: left !important;
    line-height: 50px;
  }

  .main-nav ul li.burger-bar {
    display: inline-block !important;
    /* width: 30px; */
    color: white !important;
    transition: 0.3s ease-in;
  }

  .main-nav .menu ul li {
    display: inline-block;
    margin-right: 5px !important;
    transition: 0.3s ease;
  }

  .main-nav input[type="text"].seach-people {
    min-width: 150px;
    width: 150px !important;
    max-width: 150px;
  }

  .main-nav .avatar-menu {
    top: 0px;
  }
}

.noti-panel {
  color: white !important;
  z-index: 9999;
  display: block;
  position: fixed;
  transition: 0.2s ease-in-out;
  top: 60px;
  right: 5px;
  width: 380px;
  max-width: 100%;
  height: 420px;
  background-color: #333;
  background-color: rgba(3, 3, 3, 0.85);
  -webkit-backdrop-filter: blur(5);
          backdrop-filter: blur(5);
  /* overflow-y: scroll; */
  border: 1px solid #666;
  box-shadow: 2px -2px 20px #000;
  opacity: 1;
  border-radius: 20px 0 0 20px;
}

.noti-panel .header {
  border-bottom: 1px solid #666;
}
.noti-panel .body {
  height: 350px;
  overflow-y: scroll;
}

.noti-panel.hidden {
  height: 0;
  /* overflow: hidden; */
  opacity: 0;
  transition: 0.2s ease-in-out;
  z-index: -1000;
}

.noti-panel ul li {
  border-bottom: 1px solid #666;
}

/* track */
.noti-panel .body::-webkit-scrollbar {
  background-color: #666;
  border-radius: 2px;
}

/* Handle */
.noti-panel .body::-webkit-scrollbar-thumb {
  background: seagreen;
  border-radius: 5px;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.noti-panel .body::-webkit-scrollbar-thumb:hover {
  background: maroon;
  border-radius: 5px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .noti-panel {
    width: 100%;
    height: 100%;
    right: 0;
    border-radius: 0;
  }
}

.hash-tags {
  display: block;
  min-height: 100vh;
  margin-top: 80px;
  border-radius: 5px;
  width: 640px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.hash-tags ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.hash-tags a:link,
.hash-tags a:visited {
  color: steelblue !important;
}

.hash-tags a:hover {
  color: maroon !important;
}

.hash-tags .avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-bottom: -15px;
  margin-right: 10px;
  max-width: 100%;
  position: relative;
  width: 40px;
  /* border: 1px solid #ccc; */
}

.hash-tags .post-thumbs {
  margin-bottom: 10px;
}
.hash-tags .post-thumbs img {
  width: auto;
  height: 75px;
  margin-right: 1px;
  cursor: pointer;
}

.hash-tags .post-thumbs video {
  width: 100%;
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.hash-tags button.go {
  padding: 6px 10px !important;
  border-radius: 0 5px 5px 0;
}

.hash-tags input[type="text"].search {
  padding: 5px 5px !important;
  background-color: #f5f5f5;
  border: 1px solid #999;  
  font-size: small;
  min-width: 225px;
  max-width: 100%;
  border-radius: 5px 0 0 5px;
}

/* containers */
/* below images are dummy or their locations will be coming from the database records. */

.info-layout {
  margin-top: 100px;
}
.info-layout .body-container {
  /* color:white; */
  z-index: 10;
  width: 640px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  min-height: 100vh;
  margin-bottom: 100px;

  /* border: 1px solid #f1f1f1; */
  border-top: 0;
  border-radius: 0 0 15px 15px;
}

.info-layout .body-container .page {
  z-index: 11;
  min-height: 1024px;
}

.info-layout ul {
  margin-left: 20px !important;
  padding: 5px !important;
  list-style: square;
  line-height: 1.75em;
}

.cookie-policy {
  display: block;
}

.cookie-policy ul {
  margin-left: 15px;
}

.cookie-policy a:link,
.cookie-policy a:visited {
  color: steelblue !important;
  border-bottom: 1px solid #ccc !important;
}

.cookie-prompt {
  opacity: 0;
  position: fixed;
  bottom: 280px;
  right: -400px;
  z-index: 9999;
  min-height: 100px;
  width: 380px;
  max-width: 100%;
  background-color: #666;
  color: white;
  transition: 0.3s ease-in-out;
  border: 1px solid #666;
  border-radius: 20px 0 0 0px;
}

.cookie-prompt.show {
  right: 0 !important;
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.cookie-prompt button {
  padding: 5px 10px 8px 10px;
}

.cookie-prompt .title {
  position: relative;
  top: -5px;
}

@media screen and (max-height: 640px) {
  .cookie-prompt {
    bottom: 0;
  }
}

.collab-layout {
  width: 100%;
  display: block;
  height: 100vh;
  margin-top: 55px;
  /* border-radius: 5px; */
  padding-bottom: 0;
  margin-bottom: 55px;
}

.collab-layout .flex-pane {
  display: flex;
  flex-wrap: nowrap;
  background-color: #f1f1f1;
}

.collab-layout .flex-pane .contact-list {
  min-height: calc(100vh - 100px);
  width: 280px;
  flex-shrink: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #333;
  border-right: 1px solid #ccc;
}

.collab-layout .flex-pane .chat-list {
  flex-grow: 1;
}

.collab-layout .chat-panel {
  min-height: 200px;
  width: 640px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.collab-layout .chat-panel .chat-messages a:link,
.collab-layout .chat-panel .chat-messages a:visited {
  color: steelblue;
}
.collab-layout .chat-panel .chat-messages a:hover {
  border-bottom: 1px solid steelblue;
}

.collab-layout .chat-title {
  border-bottom: 1px solid #ccc;
}

.collab-layout textarea.chat-box {
  position: fixed;
  bottom: 0;
  resize: none;
  font-size: 1.25em;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  word-break: break-all;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.collab-layout input[type="text"].search-msg {
  margin: 0;
  padding: 5px;
  border: 1px solid #ccc;
  min-width: 100%;
  max-width: 100%;
  border-radius: 3px;
  background-color: #f7f7f7;
}

.collab-layout input[type="text"].search-people,
.collab-layout input[list].search-people {
  margin: 0;
  padding: 8px;
  font-size: small;
  border: 0;
  min-width: 98%;
  max-width: 98%;
  border-radius: 3px;
  background-color: #f7f7f7;
}

.collab-layout .msg-input {
  width: 100%;
  min-height: 40px;
  /* background-color: #333; */
}

/* .collab-layout textarea {
  border: 0;  
  font-weight: normal !important;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.35em;
} */

.collab-layout ul.groups {
  list-style: none;
  margin: 0;
  padding: 0;
}

.collab-layout ul.groups li {
  padding: 7px;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-out;
  border-radius: 4px;
}

.collab-layout ul.groups li:hover {
  background-color: maroon;
  color: white;
  transition: 0.3s ease-in;
}

.collab-layout ul.groups li.active {
  background-color: maroon;
  color: white;
  text-align: right;
  transition: 0.3s ease-in;
}

.collab-layout ul.groups li.has-message {
  font-weight: bold;
  text-align: left;
}

.collab-layout ul.groups li.no-message {
  font-weight: normal;
  text-align: right;
}

.collab-layout ul.messages {
  list-style: none;
  padding: 0;
  margin: 0;
}

.collab-layout ul.messages li {
  padding: 7px;
  transition: 0.3s ease-out;
}

.collab-layout .send-btn {
  position: fixed;
  bottom: 12px;
  margin-right: 0;
  transition: 0.3s ease-out;
}

.collab-layout .send-btn:hover {
  color: maroon;
  transition: 0.3s ease-in;
}

.collab-layout h2 {
  margin: 10px;
  margin-bottom: 5px;
}

.collab-layout .chat-avatar {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid #eee;
  border: 1px solid #ccc;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.collab-layout .chat-avatar.big {
  width: 64px;
  height: 64px;
}

.collab-layout .chat-avatar2 {
  display: inline-block;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  border: 1px solid #eee;
  border: 1px solid #ccc;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.collab-layout .chat-link a:link {
  color: transparent;
  text-decoration: none;
}

.collab-layout .my-message {
  background-color: maroon;
  color: white;
  padding: 10px 10px;
  border-radius: 20px 0 20px 20px;
  font-size: 0.85em;
  margin-top: 5px;
}

.collab-layout .my-message a:link,
.collab-layout .my-message a:visited {
  color: white !important;
  border-bottom: 1px solid white;
}

.collab-layout .their-message {
  background-color: #e5e5e5;
  color: black;
  padding: 10px 10px;
  border-radius: 0px 20px 20px 20px;
  font-size: 0.85em;
  margin-top: 5px;
}

.collab-layout .their-message a:link,
.collab-layout .their-message a:visited {
  color: steelblue !important;
  border-bottom: 1px solid steelblue !important;
}

.collab-layout .chat-link a:link:hover {
  color: transparent;
  text-decoration: none;
  border: 0;
}

.collab-layout .search-people {
  display: block;
  z-index: -100;
  position: absolute;
  width: 300px;
  height: 200px;
  background-color: maroon;
  color: white;
  border-radius: 0 5px 5px 0;
  transition: 0.3s ease-in;
  opacity: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.collab-layout .search-people.shown {
  z-index: 100;
  opacity: 1;
  position: absolute;
  transition: 0.3s ease-in;
}

.collab-layout .media-bar {
  border-top: 1px solid #ccc;
}

/* Chat tool view */
.chat-tool {    
  background-color: #000;    
    color: white;
    height: 38px;    
    /* border-bottom: 1px solid #666;     */
}
