.modal {
  backdrop-filter: blur(5px);
  background-color: #333;
  background-color: rgba(50, 50, 50, 0.1);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: 0.3s ease-in;
  width: 100%;
  z-index: 9998;
}

.hide-modal {
  transition: 0.3s ease-out;
  /* display: none; */
  opacity: 0;
  z-index: -10;
  /* top: -100%;   */
}

.hide-dialog {
  transition: 0.3s ease-out;
  display: none;
  z-index: -1000;
  /* top: -100%;   */
}

/* ============================================================= */

.dialog-box {
  color: white;
  transition: 0.3s ease-in;
  min-height: 50px;
  width: 480px;
  max-width: 88%;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 30%;

  background-color: #333;
  background-color: rgba(60, 60, 60, 0.5);
  border: 1px solid #ccc;
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 30px 10px #333;
  border-radius: 0px 0px 10px 10px;

  transform: translateY(-30%);
  transform: translateX(-50%);
}

@media screen and (max-width: 640px) {
  .dialog-box {
    left: 20px;
    width: 380px;
    transform: translateY(-30%);
    transform: translateX(0%);
  }
}

.dialog-box .flex-d {
  display: flex;
  align-items: center;
}

.dialog-box hr.d {
  margin: 0 !important;
  border: 0;
  border-top: 1px solid #ccc;
}

.dialog-box .pad {
  padding: 10px;
}

.dialog-box .head {
  position: relative;
  /* top: 5px; */
  font-weight: bold;
  text-transform: uppercase;
  width: inherit;
  overflow: hidden;
}

.dialog-box .msg-area {
  position: relative;
  top: 3px;
  line-height: 1.5em;
  font-size: 0.85em;
}

/* .dialog-box hr {
  margin: 0;
  padding: 0;
  border: 0;
  border-bottom: 1px solid #ccc;
} */

.dialog-box .message-area {
  min-height: 100px;
}

.dialog-box textarea {
  width: 95%;
  padding: 5px;
  margin: 0;
  box-sizing: border-box;
  font-size: large;
  height: 80px;
}

.dialog-box input[type="button"] {
  /* margin-left: 5px; */
  background-color: rgba(30, 30, 30, 0.1);
  /* border: 1px solid #999; */
  color: white;
  cursor: pointer;
  font-family: "system-ui", Verdana, Geneva, Tahoma, sans-serif;
  font-size: small;
  margin-top: 0px;
  outline: none;
  padding: 8px 10px;
  text-transform: uppercase;
  min-width: 80px;
}

.dialog-box input[type="button"]:first-child {
  border-radius: 5px 0 0 0;
}

.dialog-box input[type="button"]:last-child,
.dialog-box input[type="button"]:only-child {
  border-radius: 0 0px 5px 0;
}

.dialog-box input[type="button"]:not(:last-child) {
  border: 0;
  border-left: 1px solid #999;
}

.dialog-box input[type="button"]:hover {
  background-color: #000;
}
/* .dialog-box input[type="button"]:only-child {
  border-radius: 5px;
} */

.dialog-box input[type="text"] {
  width: 350px;
  max-width: auto;
}

.dialog-box .progress-bar {
  height: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-color: white;
  border-radius: 4px;
}
