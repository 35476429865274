.main-nav {
  z-index: 1001;
  height: 55px;
  transition: 0.5s ease-in;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  color: white !important;
  border-bottom: 1px solid #333;
}

.main-nav .noti {
  transition: 0.3s ease-in-out;
  position: absolute;
  margin-left: -7px;
  margin-top: -3px;  
  color: red;  
  /* background-color: #fff;  */
  font-weight: bold;
  padding: 0 3px;
  /* border-radius: 5px; */
  font-size: 22px;    
  padding-top: -5px;  
}

.main-nav .menu ul {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  top: -5px;
}

.main-nav .menu ul li {
  color: white !important;
  display: inline-block;
  font-size: 1.5em;
  margin-right: 15px !important;
  padding: 0 12px 0 12px;
  transition: 0.3s ease;
  vertical-align: middle;
}
.main-nav .menu ul li:hover {
  transition: 0.3s;
  color: #ffcc00 !important;
}

.main-nav .menu li.log-off:hover {
  transition: 0.3s;
  color: #ffcc00 !important;
  border-radius: 50%;
}

.main-nav ul li a:link,
.main-nav a:visited {
  position: relative;
  color: #fff !important;
  transition: 0.3s;
}

.main-nav ul li a:hover {
  /* color: maroon !important; */
  color: #ffcc00 !important;
  transition: 0.3s;
}

/* .main-nav .menu ul li:last-child {
  display: inline-block;
  margin-left: 40px;
} */

.main-nav ul li.burger-bar {
  display: none !important;
  transition: 0.3s ease-out;
  cursor: pointer;
}

/* .main-nav input[type="text"] {
  width: 100px;
  max-width: 100px;
  padding: 5px;
  font-size: small;
  border-radius: 2px;
  border: 1px solid #fff;
} */

.main-nav .dekstop-menu {
  display: block;
  transition: 0.3s ease-in;
}

.main-nav .burger-btn {
  display: none !important;
  position: relative;
  width: 100%;
  text-align: right;
  font-size: 2.5em;
  top: -5px;
  right: 10px;
  transition: 0.3s ease-in-out;
}

.main-nav .burger-btn:hover {
  color: #ffcc00;
  transition: 0.3s ease-in-out;
}

.main-nav input[type="text"] {
  border-radius: 20px;
}

/*search*/

.main-nav .search-button {
  z-index: 1;
  position: absolute;
  margin-left: -15px;
  top: -6px;
  margin-top: 16px;
  cursor: pointer;
  color: #666;
}

.main-nav .search-panel {  
  border-radius: 10px;
  border: 1px solid #ccc;
  color: black;
  height: 0px;
  opacity: 0;  
  overflow-y: hidden;
  position: absolute;
  top: 45px;
  transition: 0.3s ease-in-out;  
  width: 320px;
  z-index: -100;
}

.main-nav .search-panel.shown {
  backdrop-filter: blur(15px);
  color: white;
  background-color: rgba(30, 30, 30, 0.9);
  height: 180px;
  opacity: 1;
  overflow-y: auto;
  position: absolute;
  transition: 0.2s ease-in;
  z-index: 100;
  box-shadow: 0px 0px 30px 1px #ccc;
}

.main-nav .search-panel ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-nav .search-panel ul li {
  cursor: pointer;
  line-height: 2em;
  transition: 0.3s;
  padding: 4px;
}

.main-nav .search-panel ul li:hover {
  background-color: maroon;
  color: white;
  transition: 0.3s;
}

.main-nav .avatar-menu {
  background-color: #666;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid white;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  margin-right: 10px;
  max-width: 100%;
  position: relative;
  transition: 0.3s ease-out;
  vertical-align: middle;
  width: 35px;
  top: 3px;
}

.main-nav .avatar-menu:hover {
  border: 1px solid maroon;
  transition: 0.3s ease-in;
}

.main-nav .logo-search {
  transition: 0.3s ease-in-out;
}

/* mobile */

@media screen and (max-width: 740px) {
  .main-nav .logo-search {
    position: relative;
    transition: 0.3s ease-in-out;
    top: 5px;
  }

  .main-nav input[type="text"] {
    position: relative;
    top: 4px;
    min-width: 280px;
    width: 280px !important;
    max-width: 280px;
    box-sizing: border-box;
    border-radius: 20px;
  }

  .noti {
    color: white !important;
  }
  .main-nav .burger-btn {
    position: fixed;
    z-index: 100;
    top: 2px;
    right: 2px;
    width: 40px;
    /* border: 1px solid white; */
    display: inline-block !important;
    /* width: 100%; */
    text-align: right;
  }

  .main-nav .logo-mobile {
    position: relative;
    /* top: -14px; */
  }

  .main-nav .dekstop-menu {
    background-color: maroon;
    color: white;
    display: block;
    height: 40px;
    position: fixed;
    text-align: right;
    top: 0;
    transition: 0.3s ease-in;
    width: 100%;
    z-index: 101;
  }

  .main-nav .dekstop-menu ul {
    margin: 0;
  }

  .main-nav .dekstop-menu.shown {
    top: -100px;
    transition: 0.3s ease-in;
  }

  .main-nav .dekstop-menu a:link,
  .main-nav .dekstop-menu a:visited {
    color: white !important;
    text-align: left !important;
    line-height: 50px;
  }

  .main-nav ul li.burger-bar {
    display: inline-block !important;
    /* width: 30px; */
    color: white !important;
    transition: 0.3s ease-in;
  }

  .main-nav .menu ul li {
    display: inline-block;
    margin-right: 5px !important;
    transition: 0.3s ease;
  }

  .main-nav input[type="text"].seach-people {
    min-width: 150px;
    width: 150px !important;
    max-width: 150px;
  }

  .main-nav .avatar-menu {
    top: 0px;
  }
}
