.uni-broadcast {
  display: block;
  width: 300px;
  max-width: 100%;
}

.uni-broadcast a:link,
.uni-broadcast a:visited {
  color: steelblue;
  transition: 0.3s ease-in-out;
}

.uni-broadcast a:hover {
  border-bottom: 1px solid steelblue;
  transition: 0.3s ease-in-out;
}

.uni-broadcast ul {
  list-style: none;
}

.uni-broadcast .bracket {
  border-left: 3px solid #f1f1f1;
  padding-left: 15px;
}

/* .uni-broadcast ul li {
  border-left: 3px solid #ccc;
  padding-left: 10px;
} */

.uni-broadcast ul li:empty {
  border: 0;
  padding-left: 5px;
}

.uni-broadcast .post-thumbs img {
  width: auto;
  height: 40px;
  margin-right: 1px;
  cursor: pointer;
}

.uni-broadcast .post-thumbs video {
  width: 100%;
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

@media screen and (max-width: 840px) {
  .uni-broadcast {
    width: 100%;
  }
}
