/* containers */
/* below images are dummy or their locations will be coming from the database records. */

.info-layout {
  margin-top: 100px;
}
.info-layout .body-container {
  /* color:white; */
  z-index: 10;
  width: 640px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  min-height: 100vh;
  margin-bottom: 100px;

  /* border: 1px solid #f1f1f1; */
  border-top: 0;
  border-radius: 0 0 15px 15px;
}

.info-layout .body-container .page {
  z-index: 11;
  min-height: 1024px;
}

.info-layout ul {
  margin-left: 20px !important;
  padding: 5px !important;
  list-style: square;
  line-height: 1.75em;
}
