.cookie-prompt {
  opacity: 0;
  position: fixed;
  bottom: 280px;
  right: -400px;
  z-index: 9999;
  min-height: 100px;
  width: 380px;
  max-width: 100%;
  background-color: #666;
  color: white;
  transition: 0.3s ease-in-out;
  border: 1px solid #666;
  border-radius: 20px 0 0 0px;
}

.cookie-prompt.show {
  right: 0 !important;
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.cookie-prompt button {
  padding: 5px 10px 8px 10px;
}

.cookie-prompt .title {
  position: relative;
  top: -5px;
}

@media screen and (max-height: 640px) {
  .cookie-prompt {
    bottom: 0;
  }
}
