.connections {
  transition: 0.5s;
  margin-top: 60px;
}

.avatar-block {
  min-width: 270px;
  max-width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline-block;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
  border: 1px solid #e1e1e1;
  font-size: 0.8em;
  text-transform: uppercase;
}

.avatar-block:hover {
  transition: 0.5s ease-in-out;
  border: 1px solid saddlebrown; 
}

.connections .f-count {
   border: 1px solid #333;
   border-radius: 10px;
   padding: 5px;
   min-width: 50px;
   display: inline-block;
}
.connections input[type="text"] {
  border-radius: 20px;
}

.connections button.go {
  padding: 6px 10px !important;
  border-radius: 0 5px 5px 0;
}

.connections input[type="text"].search {
  padding: 8px 8px !important;
  background-color: #f5f5f5;
  border: 1px solid #999;
  font-size: small;
  min-width: 225px;
  max-width: 100%;
  border-radius: 20px;
}
