.hero-banner {
  /* margin-top: 60px; */
  display: flex;
  background-color: #333;
  background-position: center 65%;
  background-repeat: no-repeat;
  height: 350px;  
  background-size: cover;        
}


