.collab-layout {
  width: 100%;
  display: block;
  height: 100vh;
  margin-top: 55px;
  /* border-radius: 5px; */
  padding-bottom: 0;
  margin-bottom: 55px;
}

.collab-layout .flex-pane {
  display: flex;
  flex-wrap: nowrap;
  background-color: #f1f1f1;
}

.collab-layout .flex-pane .contact-list {
  min-height: calc(100vh - 100px);
  width: 280px;
  flex-shrink: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #333;
  border-right: 1px solid #ccc;
}

.collab-layout .flex-pane .chat-list {
  flex-grow: 1;
}

.collab-layout .chat-panel {
  min-height: 200px;
  width: 640px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.collab-layout .chat-panel .chat-messages a:link,
.collab-layout .chat-panel .chat-messages a:visited {
  color: steelblue;
}
.collab-layout .chat-panel .chat-messages a:hover {
  border-bottom: 1px solid steelblue;
}

.collab-layout .chat-title {
  border-bottom: 1px solid #ccc;
}

.collab-layout textarea.chat-box {
  position: fixed;
  bottom: 0;
  resize: none;
  font-size: 1.25em;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  word-break: break-all;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.collab-layout input[type="text"].search-msg {
  margin: 0;
  padding: 5px;
  border: 1px solid #ccc;
  min-width: 100%;
  max-width: 100%;
  border-radius: 3px;
  background-color: #f7f7f7;
}

.collab-layout input[type="text"].search-people,
.collab-layout input[list].search-people {
  margin: 0;
  padding: 8px;
  font-size: small;
  border: 0;
  min-width: 98%;
  max-width: 98%;
  border-radius: 3px;
  background-color: #f7f7f7;
}

.collab-layout .msg-input {
  width: 100%;
  min-height: 40px;
  /* background-color: #333; */
}

/* .collab-layout textarea {
  border: 0;  
  font-weight: normal !important;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.35em;
} */

.collab-layout ul.groups {
  list-style: none;
  margin: 0;
  padding: 0;
}

.collab-layout ul.groups li {
  padding: 7px;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-out;
  border-radius: 4px;
}

.collab-layout ul.groups li:hover {
  background-color: maroon;
  color: white;
  transition: 0.3s ease-in;
}

.collab-layout ul.groups li.active {
  background-color: maroon;
  color: white;
  text-align: right;
  transition: 0.3s ease-in;
}

.collab-layout ul.groups li.has-message {
  font-weight: bold;
  text-align: left;
}

.collab-layout ul.groups li.no-message {
  font-weight: normal;
  text-align: right;
}

.collab-layout ul.messages {
  list-style: none;
  padding: 0;
  margin: 0;
}

.collab-layout ul.messages li {
  padding: 7px;
  transition: 0.3s ease-out;
}

.collab-layout .send-btn {
  position: fixed;
  bottom: 12px;
  margin-right: 0;
  transition: 0.3s ease-out;
}

.collab-layout .send-btn:hover {
  color: maroon;
  transition: 0.3s ease-in;
}

.collab-layout h2 {
  margin: 10px;
  margin-bottom: 5px;
}

.collab-layout .chat-avatar {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid #eee;
  border: 1px solid #ccc;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.collab-layout .chat-avatar.big {
  width: 64px;
  height: 64px;
}

.collab-layout .chat-avatar2 {
  display: inline-block;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  border: 1px solid #eee;
  border: 1px solid #ccc;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.collab-layout .chat-link a:link {
  color: transparent;
  text-decoration: none;
}

.collab-layout .my-message {
  background-color: maroon;
  color: white;
  padding: 10px 10px;
  border-radius: 20px 0 20px 20px;
  font-size: 0.85em;
  margin-top: 5px;
}

.collab-layout .my-message a:link,
.collab-layout .my-message a:visited {
  color: white !important;
  border-bottom: 1px solid white;
}

.collab-layout .their-message {
  background-color: #e5e5e5;
  color: black;
  padding: 10px 10px;
  border-radius: 0px 20px 20px 20px;
  font-size: 0.85em;
  margin-top: 5px;
}

.collab-layout .their-message a:link,
.collab-layout .their-message a:visited {
  color: steelblue !important;
  border-bottom: 1px solid steelblue !important;
}

.collab-layout .chat-link a:link:hover {
  color: transparent;
  text-decoration: none;
  border: 0;
}

.collab-layout .search-people {
  display: block;
  z-index: -100;
  position: absolute;
  width: 300px;
  height: 200px;
  background-color: maroon;
  color: white;
  border-radius: 0 5px 5px 0;
  transition: 0.3s ease-in;
  opacity: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.collab-layout .search-people.shown {
  z-index: 100;
  opacity: 1;
  position: absolute;
  transition: 0.3s ease-in;
}

.collab-layout .media-bar {
  border-top: 1px solid #ccc;
}

/* Chat tool view */
.chat-tool {    
  background-color: #000;    
    color: white;
    height: 38px;    
    /* border-bottom: 1px solid #666;     */
}