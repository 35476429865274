.cookie-policy {
  display: block;
}

.cookie-policy ul {
  margin-left: 15px;
}

.cookie-policy a:link,
.cookie-policy a:visited {
  color: steelblue !important;
  border-bottom: 1px solid #ccc !important;
}
