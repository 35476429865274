.comment-box {
  display: block;
  background-color: white;  
}

.comment-box a.fullname:link,
.comment-box a.fullname:visited {
  color: steelblue;
}

.comment-box a.fullname:hover {
  border-bottom: 1px solid steelblue;
}

.comment-box input[type="button"] {
  border-radius: 0 0 5px 5px;
  border: 1px solid #f1f1f1;
  margin-right: 10px;
  margin-top: -4px;
}

.comment-box input[type="text"] {
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #eee;
}

.comment-box .c-box {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.comment-box .msg-body {
  display: block;
}

.comment-box .mr-sm {
  margin-left: 10px
}

.comment-box .msg-body.scroll {
  display: block;
  overflow-y: auto;
  height: 540px;
}

.comment-box div.comment-entry {
  color: black;
  background-color: transparent;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid #ddd;
  display: inline-block;
  margin: 0;
  max-width: 280px;
  min-height: 20px;
  outline: none;
  padding: 10px;
  position: relative;
  width: 80%;
}

.comment-box .avatar-comment {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-bottom: -15px;
  margin-right: 10px;
  /* max-width: 200px; */
  position: relative;
  width: 40px;
  word-wrap: break-word;
}
.comment-box ul {
  list-style: none;
}
.comment-box ul li {
  /* border: 1px solid #f1f1f1; */

  border-radius: 15px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.comment-box .user-comment {
  margin-top: 10px;
  background-color: #f9f9f9;
  border-radius: 0px 5px 20px 5px;
  border: 1px solid rgb(200, 228, 248);
  word-break: break-all;
  color: black !important;
}

.comment-box .my-comment {
  word-break: break-all;
  width: auto;
  margin-top: 10px;
  /* background-color: rgb(233, 238, 243); */
  background-color: #fff;
  border-radius: 0px 0px 5px 20px;
  border: 1px solid rgb(212, 205, 171);
  background-color: #f9f9f9;
}

.comment-box .reload:hover {
  transition: 0.3s;
  color: green !important;
  cursor: pointer;
}

/* 
.comment-box .show-more {
} */
