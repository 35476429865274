.social-bar {
  display: block;
}

.social-bar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
  line-height: 40px;
  border: 1px solid #f1f1f1;
  border-left: 0;
  border-right: 0;
}

.social-bar ul li {
  position: relative;
  padding-right: 10px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  transition: 0.3s ease-out;
  font-size: 1.75em;
}

.social-bar ul li.selected {
  color: maroon;
}

.social-bar ul li.admin {
  font-size: 1.75em;
}

.social-bar ul li.cntd {
}

.social-bar ul li.msg {
  line-height: 50px;
  top: -2px;
}

.social-bar ul li:hover {
  color: maroon;
  transition: 0.3s ease-in;
}

.social-bar ul li:not(:last-child) {
  margin-right: 10px;
}

.social-bar hr.d {
   margin: 0px 0;
}