.profile-header {
  height: auto;
  position: relative;
  top: -140px;
  transition: 0.3s ease-in-out;
  width: 300px;
  box-sizing: border-box;
  margin: 0;
  margin-bottom: -140px;
  /* word-break: break-all !important; */
  /* border: 1px solid #ccc; */
}

.profile-header.stick {
  position: fixed;
  top: 50px;
  bottom: unset;
  transition: 0.3s ease;
}

.profile-header.stick-b {
  bottom: 320px;
  position: fixed;
  top: unset;
  transition: 0.3s ease;
}

.profile-header a:link,
.profile-header a:visited {
  color: steelblue !important;
}

.profile-header .mention {
  color: mediumseagreen;
  text-transform: lowercase;
}

.profile-header a:hover {
  border-bottom: 1px solid steelblue;
}

@media screen and (max-width: 840px) {
  .profile-header {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: -120px;
    margin-left: 5px;
    overflow-y: hidden;
    position: relative;
    width: 100%;
  }

  .profile-header .lead-head {
    /* position: relative; */
    display: block;
  }
}

.profile-header ul {
  line-height: 2em;
  list-style: none;
  margin: 0;
  padding: 0;
}

.profile-header textarea {
  border-radius: 20px;
  font-size: large !important;
}

.line-h {
  line-height: 2em;
}

.name-plate {
  text-transform: uppercase;
  color: black;
  font-size: 1.25em;
  font-weight: 800;
}
.connection-block {
  border-left: 5px solid #ccc;
  padding-left: 10px;
}
.avatar {
  background-color: #666;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  border: 3px solid #fff;
  height: 150px;
  width: 150px;
}

.role-block {
  background-color: maroon;
  border-radius: 50%;
  /* border: 1px solid white; */
  bottom: -40%;
  color: white;
  font-size: 1.2em;
  height: 35px;
  width: 34px;
  position: relative;
  right: -90%;
  text-align: center;
  font-size: 1.3em;
}

.role-block.admin {
  background-color: maroon;
  color: white;
  border: 1px solid #ccc;
  line-height: 30px;
  font-size: 1.5em;
}

.role-block.dev {
  position: relative;
  background-color: maroon;
  color: white;
  line-height: 30px;
  border: 2px solid #ccc;
}

.role-block.uni {
  background-color: maroon;
  border: 1px solid #ccc;
  color: white;
  font-size: 1em;
}

.profile-header .case-report {
  position: relative;
  bottom: 25px;
  color: #ff6600;
  cursor: pointer;
}

.profile-header .case-report:hover {
  color: red;
}
